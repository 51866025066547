export const AT_SET_SELECTED_ORDER = 'SET_SELECTED_ORDER';
export const AT_SET_SELECTED_ATTCHMNT = 'SET_SELECTED_ATTACHMENT';

export const setSelectedOrder = (order) => ({
  'type': AT_SET_SELECTED_ORDER,
  payload: { order }
});

export const setSelectedAttachment = (attachment) => ({
  'type': AT_SET_SELECTED_ATTCHMNT,
  payload: { attachment }
});
