import React from "react";

export const Notification = props => {
  return (
    <div className={`notification__message ${props.notification.className}`}>
      <i className="fas fa-info-circle" />
      {props.notification.message}
    </div>
  );
};
