import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DateRangePicker } from 'react-date-range';
import { setStartDateRangeOrder } from "../../actions";
import "../../styles/styles-date-picker.scss"
import moment from "moment";

const OrderFilterMenu = ({ setStartDateRangeOrder, startDate, endDate }) => {

  const [showSelector, setShowSelector] = useState(false)

  const handleSelect = (ranges) => {
    setStartDateRangeOrder(ranges.selection.startDate, ranges.selection.endDate)
  }

  return <div className='override-default-styles'>
    <div className="">
      <label className='menu-filter-label'>Date Range</label>
      <input type="text" className="dateRangeDisplay" onClick={() => setShowSelector(true)} readOnly value={`${moment(startDate).format('MMM D, \'YY')} - ${moment(endDate).format('MMM D, \'YY')}`}/>
    </div>
    <div className='dateRangePopup' style={{ display: showSelector ? 'flex' : 'none' }}>
      <div className="content">
        <DateRangePicker showDateDisplay={false} months={2} onChange={handleSelect} ranges={[{ startDate, endDate, key: 'selection' }]}/>
        <div className="close-button" onClick={() => setShowSelector(false)}>
          <span>X</span>
        </div>
      </div>
    </div>
  </div>
};
OrderFilterMenu.propTypes = {
  setStartDateRangeOrder: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  startDate: state.filters.dateStart,
  endDate: state.filters.dateEnd
});

export default connect(mapStateToProps, { setStartDateRangeOrder })(OrderFilterMenu);
