import React from "react";
import Modal from "react-modal";

const renderNoButton = closeUpdateModal => (
  <span>
    &nbsp;
    <button className="button--default" onClick={closeUpdateModal}>
      Cancel
    </button>
  </span>
);

const renderButton = (order, handleUpdateOrder, closeUpdateModal) => {
  if (!order || !order.currentStatus) return "";
  switch (order.currentStatus.status) {
    case "PENDING":
      return (
        <span>
          {renderNoButton(closeUpdateModal)}
          &nbsp;
          <button
            className="button--default button--danger"
            onClick={() => handleUpdateOrder("REJECTED")}
          >
            Reject
          </button>
          &nbsp;
          <button
            className="button--default button--primary"
            onClick={() => handleUpdateOrder("ACCEPTED")}
          >
            Approve
          </button>
        </span>
      );
    case "ACCEPTED":
      return (
        <span>
          {renderNoButton(closeUpdateModal)}
          &nbsp;
          <button
            className="button--default button--success"
            onClick={() => handleUpdateOrder("DISPATCHED")}
          >
            Dispatch
          </button>
        </span>
      );
    case "ON_HOLD":
      return (
        <span>
          {renderNoButton(closeUpdateModal)}
          &nbsp;
          <button
            className="button--default button--primary"
            onClick={() => handleUpdateOrder("DISPATCHED")}
          >
            Resume
          </button>
        </span>
      );
    case "REJECTED":
    case "DISPATCHED":
    default:
      return "";
  }
};

export const UpdateModal = props => (
  <Modal
    style={{
      overlay: {
        backgroundColor: "rgba(0,0,0,0.3)"
      }
    }}
    isOpen={props.isUpdateModalOpen}
    onRequestClose={props.closeUpdateModal}
    contentLabel="Confirm"
    closeTimeoutMS={200}
    ariaHideApp={false}
    className="modal--update"
  >
    <h5 className="modal__title">Update Confirmation</h5>
    <p className="modal__message">
      Do you wish to continue updating this order?
    </p>
    <div className="text-right">
      {renderButton(
        props.order,
        props.handleUpdateOrder,
        props.closeUpdateModal
      )}
    </div>
  </Modal>
);
