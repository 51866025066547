import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { startLogout, setTextFilter, setHelpModal } from '../../actions'

const Header = ({
  filter_text,
  startLogout,
  setTextFilter,
  auth: { user },
  setHelpModal,
}) => {
  return (
    <div className='header'>
      <div className='container'>
        <h1 className='header__title'>
          <img src='/images/reach52_logistics.png' alt='Dispergo' />
        </h1>
        {user.roles.includes('pharmacist') && (
          <form
            className='search-bar'
            role='search'
            onSubmit={(e) => e.preventDefault()}
          >
            <label className='label__input'>
              <i className='fas fa-search' />
              <input
                className='input--default'
                type='text'
                placeholder='Search for patient and agent'
                value={filter_text}
                onChange={(e) => {
                  setTextFilter(e.target.value)
                }}
              />
            </label>
          </form>
        )}
        <div className="header-menu">
          <div className="header__link"> 
            <input type='checkbox' id='logoutBtn'/>  
            <button className='logout-button'>
              <label htmlFor='logoutBtn'><i className="fas fa-sign-out-alt" />Logout</label></button>
                <div className='modal-prompt modal-logout'>
                  <div className='modal' tabIndex='-1' role='dialog'>
                    <div className='modal-dialog' role='document'>
                      <div className='modal-content'>
                        <div className='modal-header'>
                          <h5 className='modal-title'>Confirmation</h5>
                          <button
                            type='button'
                            className='close'
                            data-dismiss='modal'
                            aria-label='Close'
                          >
                            <span aria-hidden='true'>&times;</span>
                          </button>
                        </div>
                        <div className='modal-body'>
                          <p>
                            Are you sure do you want to <b>Logout?</b>
                          </p>
                          
                        </div>
                        <div className='modal-footer'>
                          <button
                            type='button'
                            className='btn btn-default'
                            data-dismiss='modal'
                          >
                            <label htmlFor='logoutBtn'>Close</label>
                          </button>
                          <button
                            type='button'
                            className='btn button--primary button--default'
                            onClick={(e) => {
                              startLogout();
                            }}
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
          <div className="help-menu">
            <button className="help-button" onClick={() => {
              setHelpModal(true);
            }}>
              <i className="fa fa-question-circle" />
              Help
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  startLogout: PropTypes.func.isRequired,
  filter_text: PropTypes.string,
  setTextFilter: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  setHelpModal: PropTypes.func,
}

const mapStateToProps = (state) => ({
  filter_text: state.filters.text,
  auth: state.auth,
})

export default connect(mapStateToProps, { startLogout, setTextFilter, setHelpModal })(
  withRouter(Header)
)
