import React from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { AUTH_CLIENT_HOST_URL } from '../constants/centralizedAuthHostUrls'

export const PrivateRoute = ({
  isAuthenticated,
  component: Component,
  cur_process: message,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      !isAuthenticated ? (
        // <Redirect to='/' />
        window.location.replace(AUTH_CLIENT_HOST_URL)
      ) : (
        <div className='browser-wrapper'>
          <Component {...props} />
        </div>
      )
    }
  />
)
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  cur_process: state.cur_process,
})
export default connect(mapStateToProps)(PrivateRoute)
