import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useTable, useFilters, usePagination } from "react-table";
import { connect } from "react-redux";
import { ExportToCsv } from "export-to-csv";
import moment from "moment";
import { toggleInsuranceModal } from "../../actions/util";
import { getInscuranceQuestionsById } from '../../Apis/InscuranceApis/inscranceApi';

let exportFilter = "";

// This is a custom filter UI for selecting
// a unique option from a list
const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        exportFilter = e.target.value;
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search records...`}
    />
  );
}

let residentMapper = async (namesData) => {
  let residentName = [];
  await namesData && namesData.map(name => {
    return residentName.push(name.props.children && name.props.children)
  })
  return residentName;
}

let dobMapper = async (dobData) => {
  let dobs = [];
  await dobData && dobData.map(name => {
    if (name.props) {
      return dobs.push(name.props.children)
    } else {
      return dobs.push(name)
    }
  })
  return dobs;
}

let ageMapper = async (ageData) => {
  let age = [];
  await ageData && ageData.map(item => {
    if (item.props) {
      return age.push(item.props.children)
    } else {
      return age.push(item)
    }
  })
  return age;
}

let genderMapper = async (genederData) => {
  let geneders = [];
  await genederData && genederData.map(item => {
    if (item.props) {
      return geneders.push(item.props.children)
    } else {
      return geneders.push(item)
    }
  })
  return geneders;
}

let filter = async (data) => {
  let newFilterData = [];
  await data.map(async (item) => {
    // let getResident = await residentMapper(item.residentName)
    let getDobs = await dobMapper(item.dateOfBirth)
    let getAges = await ageMapper(item.age)
    let genders = await genderMapper(item.gender)

    // item.residentName = getResident;
    item.gender = genders;
    item.age = getAges;
    item.dateOfBirth = getDobs;
    newFilterData.push(item)
    return newFilterData
  })
  return newFilterData;
}

const generateCSV = async (data) => {
  data =
    exportFilter === ""
      ? data
      : data.filter((item) => item.status === exportFilter);

  const result = await data.map((item, index) => {
    let {
      certificateNo,
      residentName,
      dateOfBirth,
      age,
      gender,
      // civilStatus,
      insuranceSupplier: { suppName },
      title,
      price,
      duration: { dateStart, dateEnd },
      createdBy,
      status,
    } = item;

    dateStart = moment(dateStart).format("ll");
    dateEnd = moment(dateEnd).format("ll");

    residentName = residentName.join()
    dateOfBirth = dateOfBirth.join()
    gender = gender.join()
    age = age.join()

    return (item = {
      certificateNo,
      residentName,
      dateOfBirth,
      age,
      gender,
      // civilStatus,
      dateStart,
      dateEnd,
      suppName,
      title,
      price,
      createdBy,
      status,
    });
  });

  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    title: "Insurance sold Plan",
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: false,
    headers: [
      "Certificate Number",
      "Policy Owner",
      "Birthdate",
      "Age",
      "Gender",
      // "Civil Status",
      "Start Date",
      "End Date",
      "Insurer",
      "Plan",
      "Price",
      "Agent",
      "Status",
    ],
  };
  try {
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(result);
  } catch (error) {
    console.log(error);
  }
};

const downlodCsv = async (data) => {
  let filtered = await filter(data);
  setTimeout(() => { generateCSV(filtered) }, 2000);
}

const columns = [
  {
    Header: "Sold Insurance Plan",
    columns: [
      {
        Header: "qualification",
        accessor: "qualification",
        filterable: false,
      },
      {
        Header: "Photo",
        accessor: "_attachments",
        filterable: false,
      },
      {
        Header: "Reason",
        accessor: "insurer.qualification",
        filterable: false,
      },
      {
        Header: "Id",
        accessor: "id",
        filterable: false,
      },
      {
        Header: "Certificate Number",
        accessor: "certificateNo",
        filterable: true,
      },
      {
        Header: "Policy Owner",
        accessor: "residentName",
        filterable: true,
      },
      {
        Header: "Policy Duration",
        accessor: "effectiveDate",
        filterable: false,
      },
      // {
      //   Header: "Civil Status",
      //   accessor: "civilStatus",
      //   filterable: false,
      // },
      {
        Header: "Gender(M/F)",
        accessor: "gender",
        filterable: false,
      },
      {
        Header: "Policy Insured",
        accessor: "insured.display",
        filterable: false,
      },
      {
        Header: "Beneficiaries",
        accessor: "coverage",
        filterable: false,
      },
      {
        Header: "Birthdate",
        accessor: "dateOfBirth",
        filterable: false,
      },
      {
        Header: "Age",
        accessor: "age",
        filterable: false,
      },
      {
        Header: "Insurer",
        accessor: "insuranceSupplier.suppName",
        filterable: false,
      },
      {
        Header: "Plan",
        accessor: "title",
        filterable: false,
      },
      {
        Header: "Price",
        accessor: "price",
        filterable: false,
      },
      {
        Header: "Agent",
        accessor: "createdBy",
        filterable: true,
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: SelectColumnFilter,
        filter: "includes",
        filterable: true,
      },
      {
        Header: "isoCountry",
        accessor: "isoCountry",
        filterable: false,
      },
    ],
  },
];


const Table = ({ columns, data, toggleModal, setExportData, country }) => {
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const openModule = async (value, row) => {
    let residentId;
    data && data.filter(obj => {
      if (obj.id === row.id) {
        residentId = obj.residentId;
      }
    })
    if (residentId) {
      var result = await getInscuranceQuestionsById(residentId, country)
    }
    if (result.length) {
      row.maritalStatus = result[0].maritalStatus
      row.questions = result[0].questions
    };
    toggleModal(value, row)
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        hiddenColumns: [
          "_attachments",
          "insurer.qualification",
          "qualification",
          "id",
          "isoCountry"
        ],
      },
      defaultColumn,
    },
    useFilters, // useFilters!
    usePagination
  );
  return (
    <Fragment>
      <div className="table-container">
        <table {...getTableProps()} className="table table-striped">
          <thead>
            {headerGroups.map((headerGroup, row_idx) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={`row_${row_idx}`}>
                {headerGroup.headers.map((column, col_idx) => (
                  <th {...column.getHeaderProps()} key={`rocol_${col_idx}`}>
                    {column.render("Header")}
                    {/* Render the columns filter UI */}
                    <div>
                      {column.filterable ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i}
                  {...row.getRowProps()}
                  onClick={(e) => openModule(true, row.values)}
                >
                  {row.cells.map((cell,index) => {
                    return (
                      <td {...cell.getCellProps()} key={index}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <div>
          <button
            className="button--accent"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </button>{" "}
          <button
            className="button--accent"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"<"}
          </button>{" "}
          <button
            className="button--accent"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {">"}
          </button>{" "}
          <button
            className="button--accent"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>{" "}
        </div>
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
      </div>
    </Fragment>
  );
};


const InsuredTable = ({
  insurer: {
    insuranceCompany: { plans, companies, insured },
    selectedCountry
  },
  toggleInsuranceModal,
}) => {
  return (
    <Fragment>
      {plans && plans.length > 0 && (
        <Table
          columns={columns}
          data={plans}
          toggleModal={toggleInsuranceModal}
          country={selectedCountry}
        />
      )}

      {plans && (
        <div
          className="button--default button-inline button--primary"
          onClick={(e) => downlodCsv(plans)}
        >
          Download CSV
        </div>
      )}
    </Fragment>
  );
};

InsuredTable.propTypes = {
  tableData: PropTypes.func.isRequired,
  insurer: PropTypes.object.isRequired,
  util: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  insurer: state.insurer,
  util: state.util,
});
export default connect(mapStateToProps, { toggleInsuranceModal })(InsuredTable);
