import { setNotification } from '../actions'
import { AT_LOGIN } from '../actions/types'
import { AUTH_CLIENT_HOST_URL } from '../constants/centralizedAuthHostUrls'

const login = (uid, isAuthenticated, error = '', user) => ({
  type: AT_LOGIN,
  uid,
  isAuthenticated,
  error,
  user,
})
const apiNotification = async (error, dispatch) => {
  if (error.response) {
    if(error.response.status === 401 || error.response.status === 400 || error.response.status === 403) {
      dispatch(setNotification(true, 'REJECTED', error.response.data.message))
      await setTimeout(() => {
        dispatch(setNotification(false, '', ''))

        dispatch(login('', false, 'Invalid session, please relogin'))
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        window.location.replace(AUTH_CLIENT_HOST_URL)
      }, 5000)
    } else {
      dispatch(setNotification(true, 'REJECTED', 'Something went wrong'))
      await setTimeout(() => {
        dispatch(setNotification(false, '', ''))
      }, 5000)
    }
  } else {
    if(error.message) {
      dispatch(setNotification(true, 'REJECTED', error.message))
      await setTimeout(() => {
        dispatch(setNotification(false, '', ''))
      }, 5000)
    } else {
      dispatch(setNotification(true, 'REJECTED', 'Something went wrong'))
      await setTimeout(() => {
        dispatch(setNotification(false, '', ''))
      }, 5000)
    }
  }
}

export default apiNotification
