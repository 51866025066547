import React, { Fragment, useState, useEffect } from 'react'
import { initOrders } from '../../src/actions/orders'
import { getInsuranceData } from '../../src/actions/insurer'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectOrders } from '../selectors/orders'
import { Notification } from '../components'
import ViewModal from '../components/ViewModal'
import Header from '../components/layout/Header'
import HelpModal from '../components/layout/HelpModal'
import Orders from '../components/Orders/Orders'
import Menu from '../components/Menu'
import FilterMenu from '../components/insurance/FilterMenu'
import InsuredTable from '../components/insurance/InsuredTable'
import InsuredGraphLine from '../components/insurance/InsuredGraphLine'
import InsuranceModal from '../components/util/InsuranceModal'
import UpdateCatalogueModal from '../components/util/UpdateCatalogueModal'
import OrdersTable from '../components/Orders/OrdersTable'
import Loading from '../components/util/Loading'
import { CrossStorageClient } from 'cross-storage'
import { startLogout } from '../actions/auth'
import {
  dispatchLoaderMessage,
  authenticateSession,
} from '../actions/sso.login'
import { AUTH_CLIENT_HOST_URL } from '../constants/centralizedAuthHostUrls'

import configureStore from '../store'
const _ = require('lodash')
export const DashboardPage = ({
  authenticateSession,
  dispatchLoaderMessage,
  startLogout,
  orders_array,
  notification,
  auth,
  insurer,
  cur_process: loadMessage,
  isAuthenticated,
  initOrders,
  getInsuranceData
}) => {
  const store = configureStore()
  const [isCard, setIsCard] = useState(true)
  useEffect(() => {
    let msg = ' '
    dispatchLoaderMessage(msg)
    msg = ''
    const hubStorage = new CrossStorageClient(AUTH_CLIENT_HOST_URL, {
      timeout: 10000,
      frameId: 'storageFrame',
    })
    if (isAuthenticated) {
      hubStorage
        .onConnect()
        .then(async () => {
          const session = JSON.parse(await hubStorage.get('session'))
          const isSessionActive = session.logistics.isActive

          if (isSessionActive) {
            let isAppIdChanged = true
            if (
              auth.user.roles.includes('pharmacist') &&
              session.logistics.appId === 'logistics-pharma' &&
              localStorage.getItem('token') === session.token
            ) {
              isAppIdChanged = false
            }
            if (
              auth.user.roles.includes('insurance') &&
              session.logistics.appId === 'logistics-insurance' &&
              localStorage.getItem('token') === session.token
            ) {
              isAppIdChanged = false
            }
            const countries = auth.user.country.iso3 === 'all' ? ['ind', 'khm', 'phl', 'ken', 'zaf'] : [auth.user.country.iso3]
            if (isAppIdChanged) {
              await authenticateSession(
                session.token,
                session.userId,
                session.logistics.appId
              )
            } else if (auth.user.roles.includes('pharmacist')) {
              let supplierCodes = auth.user.permissions.map((obj) => obj.code)
              initOrders(supplierCodes, countries)
            } else {
              let insurerCodes = auth.user.permissions.map((obj) => obj.code)
              getInsuranceData(insurerCodes, null, null, countries)
            }
          } else {
            startLogout()
            dispatchLoaderMessage(msg)
          }
        })
        .catch(async (err) => {
          console.log(err)
          startLogout()
          dispatchLoaderMessage(msg)
        })
    }
  }, [
    startLogout,
    authenticateSession,
    dispatchLoaderMessage,
    auth,
    isAuthenticated,
    initOrders,
    getInsuranceData
  ])
  return (
    <Fragment>
      {notification.show && <Notification notification={notification} />}
      {loadMessage !== '' ? (
        <Loading message={loadMessage} />
      ) : (
        <Fragment>
          <Header />
          <HelpModal />
          {/* insurance */}
          {auth.user.roles.includes('insurance') && (
            <Fragment>
              <div style={{ padding: '20px' }}>
                <h4 className='container-title text-center capitalize'>
                  {/* {auth.user.permissions[0] &&
                    auth.user.permissions[0].associate}{' '} */}
                  Insurance Plan Sales
                </h4>
                <FilterMenu />
                <InsuredGraphLine />
                <InsuredTable />
                <InsuranceModal />
              </div>
            </Fragment>
          )}
          {/* pharmacist */}
          {auth.user.roles.includes('pharmacist') && (
            <Fragment>
              <div className='container'>
                <h4 className='orders-title'>Orders</h4>
                <Menu
                  orders={orders_array}
                  setIsCard={setIsCard}
                  isCard={isCard}
                />
                {isCard ? (
                  <Orders orders={orders_array} />
                ) : (
                  <OrdersTable orders={orders_array} />
                )}
              </div>
              <ViewModal />
              <UpdateCatalogueModal />
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

DashboardPage.propTypes = {
  authenticateSession: PropTypes.func.isRequired,
  dispatchLoaderMessage: PropTypes.func.isRequired,
  startLogout: PropTypes.func.isRequired,
  orders_array: PropTypes.array.isRequired,
  meds: PropTypes.object.isRequired,
  is_notification_open: PropTypes.bool,
  notification: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getInsuranceData: PropTypes.func.isRequired
}

const mapStateToProps = (state, props) => ({
  orders_array: selectOrders(_.values(state.orders), state.filters),
  meds: state.meds,
  is_notification_open: state.ui.notification.show,
  notification: state.ui.notification,
  auth: state.auth,
  insurer: state.insurer,
  cur_process: state.cur_process,
  isAuthenticated: state.auth.isAuthenticated,
})

export default connect(mapStateToProps, {
  startLogout,
  authenticateSession,
  dispatchLoaderMessage,
  initOrders,
  getInsuranceData
})(DashboardPage)
