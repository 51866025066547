let API_URL
let SIGNED_IMAGE_URI

if (process.env.NODE_ENV === 'development') {
  API_URL = 'https://uat-logistics.reach52.com'
  SIGNED_IMAGE_URI = 'https://dev-ma-authapi.reach52.com/images'
}
if (process.env.REACT_APP_AWHDISPERGO_ENV === 'UAT') {
  API_URL = 'https://uat-logistics.reach52.com'
  SIGNED_IMAGE_URI = 'https://dev-ma-authapi.reach52.com/images'
}
if (process.env.REACT_APP_AWHDISPERGO_ENV === 'DEMO') {
  API_URL = 'https://demo-logistics.reach52.com'
  SIGNED_IMAGE_URI = 'https://demo-auth-api.reach52.com/images'
}
if (process.env.REACT_APP_AWHDISPERGO_ENV === 'LIVE') {
  API_URL = 'https://logistics.reach52.com'
  SIGNED_IMAGE_URI = 'https://auth-manager-api.reach52.com/images'
}

console.log(process.env.REACT_APP_AWHDISPERGO_ENV, 'API_URl', API_URL)
const basePath =
  window.location.hostname.indexOf('localhost') > -1
    ? 'http://localhost:8001/api/'
    : `${API_URL}/api/`

/*Medicine urls*/
export const BASE_URL = `${basePath}`
export const GET_ORDER_MEDICINE_URL = `${basePath}medicine/orders`
export const GET_ORDER_MEDICINE_BY_ID_URL = `${basePath}medicine/orders`
export const UPDATE_ORDER_MEDICINE_BY_ID_URL = `${basePath}medicine/orders`

/*Inscurance urls*/
export const GET_INSCURANCE_URL = `${basePath}insurance/allList`
export const GET_INSCURE_URL = `${basePath}inscure/getById`
export const UPDATE_INSCURANCE_STATUS_URL = `${basePath}insurance/updateStatus`
export const GET_INSCURANCE_BY_ID_URL = `${basePath}insurance/getById`
export const GET_INSCURANCE_QUESTIONS_BY_ID_URL = `${basePath}insurance/questions/getById`
export const GET_INSURER_URL = `${basePath}insurers`
export const GET_SUPPLIERS_URL = `${basePath}suppliers`

export const GET_INSURANCE_IMAGE_SIGNED_URL = `${SIGNED_IMAGE_URI}/residents/insurance`

export const CENTRAL_AUTH_IMAGES = SIGNED_IMAGE_URI
