import React, { useEffect } from 'react'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getSupplierData, initOrders, setStatusFilter, setOrderTypeFilter, setSupplierDropdownData, setCountriesForDropdown } from '../actions'
import Select from 'react-select'
import { generatePDF } from "./Orders/OrderGeneratePDF";
import { generateExcel } from "./Orders/OrderGenerateExcel";
import OrderFilterMenu from "./Orders/OrderFilterMenu";
import MultiSelect from '@material-ui/core/Select';
import { Checkbox, MenuItem, ListItemText, FormControl} from '@material-ui/core';
import { countries } from '../constants/countries.static'

const options = [
  { value: 'ALL', label: 'ALL' },
  { value: 'PENDING', label: 'PENDING' },
  { value: 'ON_HOLD', label: 'ON HOLD' },
  { value: 'REJECTED', label: 'REJECTED' },
  { value: 'ACCEPTED', label: 'ACCEPTED' },
  { value: 'DISPATCHED', label: 'DISPATCHED' },
  { value: 'RECEIVED', label: 'RECEIVED' },
  { value: 'PICKED_UP', label: 'PICKED UP' },
  { value: 'DELIVERED', label: 'DELIVERED' },
  { value: 'NOT_DELIVERED', label: 'NOT DELIVERED' },
  { value: 'RETURNED', label: 'RETURNED' }
]

const optionsExport = [
  { value: 'pdf', label: 'PDF' },
  { value: 'excel', label: 'Excel' }
]

const optionsOrderTyoe = [
  { value: 'all', label: 'ALL' },
  { value: 'order', label: 'Agent' },
  { value: 'merchant_order', label: 'Merchant' }
]

const Menu = ({
  setStatusFilter,
  setOrderTypeFilter,
  orders,
  setIsCard,
  isCard,
  filter_status,
  filter_order_type,
  filter_date_start,
  filter_date_end,
  suppliers,
  getSupplierData,
  selectedSuppliersForDropdown,
  selectedCountriesForDropdown,
  auth,
  setSupplierDropdownData,
  setCountriesForDropdown,
  initOrders
}) => {
  const handleChange = (e) => {
    setStatusFilter(e)
  }

  const handleChangeExport = (e) => {
    if(e.value === 'pdf') {
      generatePDF(orders, filter_date_start, filter_date_end)
    } else if (e.value === 'excel') {
      generateExcel(orders)
    }
  }

  const handleChangeType = (e) => {
    setOrderTypeFilter(e)
  }

  const supplierList = [] 

  useEffect(()=>{
    if (auth.user.permissions[0].associate === "All" && supplierList.length === 0) {
      if(selectedCountriesForDropdown.length === 0) {
      getSupplierData([auth.user.country.iso3], false , [])
      }
    }
    if (selectedCountriesForDropdown.length === 0) {
      if (auth.user.country.iso3 === 'all') {
        const filteredCountries = countries.filter((country)=>country.value !== 'idn')
        setCountriesForDropdown(filteredCountries.map((country)=>country.value))
      } else {
        setCountriesForDropdown([auth.user.country.iso3])
      }
    }

    if (selectedSuppliersForDropdown.length === 0) {
      setSupplierDropdownData(supplierList.map((val)=>val.value))
    }
    if(suppliers.length !== 0 && selectedSuppliersForDropdown.length === 0) {
      setSupplierDropdownData(supplierList.map((val)=>val.value))
    }
  },[setSupplierDropdownData, suppliers, auth, setCountriesForDropdown])

  if (auth.user.permissions[0].associate === "All") {
    suppliers.forEach((obj) => {
      supplierList.push({value: obj.supplierCode, label: obj.supplierName.eng})
    })
  } else {
    auth.user.permissions.forEach((obj) => {
      supplierList.push({value: obj.code, label: obj.associate})
    })
  }

  const onChange = (event) => {
    const { value } = event.target
    const supplierCodes = []
    value.forEach((obj) => {
      supplierCodes.push(obj)
    })
    setSupplierDropdownData(value)
    if (value.length === 0) {
      if (auth.user.permissions[0].associate === "All") {
        supplierCodes.push('*')
      } else {
        auth.user.permissions.forEach((obj) => {
          supplierCodes.push(obj.code)
        })
      }
    }
    initOrders(supplierCodes, selectedCountriesForDropdown)
  }

  const onCountryChange = (event) => {
    const { value } = event.target
    const selectedCountries = []
    if (value.length === 0) {
      const filteredCountries = countries.filter((country)=>country.value !== 'idn')
      setCountriesForDropdown(filteredCountries.map((country)=>country.value))
      filteredCountries.forEach((obj) => {
        selectedCountries.push(obj.value)
      })
    } else {
      if (value.includes('idn')) {
        if (value[value.length-1] !== 'idn') {
          selectedCountries.push(value[value.length-1])
          setCountriesForDropdown([value[value.length-1]])
        } else {
          selectedCountries.push('idn')
          setCountriesForDropdown(['idn'])
        }
      } else {
        value.forEach((obj) => {
          selectedCountries.push(obj)
        })
        setCountriesForDropdown(value)
      }
    }
    getSupplierData(selectedCountries, true, selectedSuppliersForDropdown)
  }

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: state.selectProps.width,
      color: state.selectProps.menuColor,
      borderRadius: "25px",
      marginTop: "2px"
    }),
  
    control: (_, { selectProps: { width }},state) => ({
      width: '100%',
      display: "flex",
      borderRadius: "25px",
      background: "white",
      border: "1px solid #8181815C",
      height: "100%"
    }),
    option:(provided, state)=>({
      ...provided,
      backgroundColor: state.isSelected?'#ab218f':"white",
      borderBottom: '0.5px solid #707070',
       textAlign :'left',
       padding: "3px 11px",
    }),
    menuList:(provided, state)=>({
      ...provided,
      borderRadius: "25px"
    }),

  
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const textAlign = 'left';
      return { ...provided, opacity, transition,textAlign };
    }
  }

  const customStylesExport = {
    menu: (provided, state) => ({
      ...provided,
      width: state.selectProps.width,
      color: state.selectProps.menuColor,
      borderRadius: "25px",
      marginTop: "2px"
    }),
  
    control: (_, { selectProps: { width }},state) => ({
      width: '100%',
      display: "flex",
      borderRadius: "25px",
      background: "white",
      border: "1px solid #8181815C",
      height: "100%"
    }),
    option:(provided, state)=>({
      ...provided,
      backgroundColor: state.isSelected?'#ab218f':"white",
       textAlign :'left',
       padding: "3px 11px",
    }),
    menuList:(provided, state)=>({
      ...provided,
      borderRadius: "25px"
    }),

  
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const textAlign = 'left';
      return { ...provided, opacity, transition,textAlign };
    }
  }

  return (
    <div className='text-center flex-space-between'>
      <div className="menu-filter">
        <div className='form-field supplier-filter'>
          <label className='menu-filter-label'>Status</label>
          <Select styles={customStyles} isSearchable={false}
            width='100%' options={options} defaultValue={ filter_status } onChange={handleChange} selected/>
        </div>
        { auth.user.country.iso3 === 'all' ? <div className='form-field supplier-filter'>
          <label className='menu-filter-label'>Country</label>
          <FormControl variant="outlined" className='filters-text-align' sx={{ m: 1 }}>
            <MultiSelect
              labelId='app-selectedCountriesForDropdown-checkbox-label'
              id='app-selectedCountriesForDropdown-checkbox'
              multiple
              value={selectedCountriesForDropdown}
              name='selectedCountriesForDropdown'
              onChange={onCountryChange}
              renderValue={(selected) => {
                let selectedItems = []
                countries.forEach((obj) => {
                  if (selected.includes(obj.value)) {
                    selectedItems.push(obj.label)
                  }
                })
                return selectedItems.join(', ')
              }}
            >
              {countries.map((country) => (
                <MenuItem key={country.value} value={country.value}>
                  <Checkbox
                    checked={selectedCountriesForDropdown.indexOf(country.value) !== -1}
                  />
                  <ListItemText primary={country.label} />
                </MenuItem>
              ))}
            </MultiSelect>
          </FormControl>
        </div> : <div></div>}
        <div className='form-field supplier-filter'>
          <label className='menu-filter-label'>Supplier</label>
          <FormControl variant="outlined" className='filters-text-align' sx={{ m: 1 }}>
            <MultiSelect
              labelId='app-selectedSuppliersForDropdown-checkbox-label'
              id='app-selectedSuppliersForDropdown-checkbox'
              multiple
              value={selectedSuppliersForDropdown}
              name='selectedSuppliersForDropdown'
              onChange={onChange}
              renderValue={(selected) => {
                let selectedItems = []
                supplierList.forEach((obj) => {
                  if (selected.includes(obj.value)) {
                    selectedItems.push(obj.label)
                  }
                })
                return selectedItems.join(', ')
              }}
            >
              {supplierList.map((supplier) => (
                <MenuItem key={supplier.value} value={supplier.value}>
                  <Checkbox
                    checked={selectedSuppliersForDropdown.indexOf(supplier.value) !== -1}
                  />
                  <ListItemText primary={supplier.label} />
                </MenuItem>
              ))}
            </MultiSelect>
          </FormControl>
        </div>
        <OrderFilterMenu />
        <div className='form-field supplier-filter'>
          <label className='menu-filter-label'>Export As</label>
          <Select styles={customStylesExport} isSearchable={false}
            width='100%' options={optionsExport} onChange={handleChangeExport} selected/>
        </div>
        <div className='form-field supplier-filter'>
          <label className='menu-filter-label'>Order Type</label>
          <Select styles={customStylesExport} isSearchable={false} defaultValue={ filter_order_type }
            width='100%' options={optionsOrderTyoe} onChange={handleChangeType} selected/>
        </div>
      </div>
      <div className="flex gap-between">
        <i title="grid" onClick={()=>{setIsCard(true)}} className={"fas fa-th-large fa-2x " + (isCard ? "icon-active" : "icon-inactive")}></i>
        <i title="list" onClick={()=>{setIsCard(false)}} className={"fas fa-th-list fa-2x " + (!isCard ? "icon-active" : "icon-inactive")}></i>
      </div>
    </div>
  );
};

Menu.propTypes = {
  setStatusFilter: PropTypes.func.isRequired,
  setOrderTypeFilter: PropTypes.func.isRequired,
  setCountriesForDropdown: PropTypes.func.isRequired,
  setSupplierDropdownData: PropTypes.func.isRequired,
  getSupplierData: PropTypes.func.isRequired,
  initOrders: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  filter_status: state.filters.status,
  filter_order_type: state.filters.orderType,
  filter_date_start: state.filters.dateStart,
  filter_date_end: state.filters.dateEnd,
  suppliers: state.suppliers.suppliers,
  selectedSuppliersForDropdown: state.suppliers.selectedSuppliersForDropdown,
  selectedCountriesForDropdown: state.suppliers.selectedCountriesForDropdown,
  auth: state.auth
});

export default connect(mapStateToProps, { 
  setStatusFilter,
  setOrderTypeFilter,
  getSupplierData,
  setSupplierDropdownData,
  initOrders,
  setCountriesForDropdown
})(Menu)
