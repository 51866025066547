import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactModal from "react-modal";
import { toggleCatalogueModal, toggleConfirmation } from "../../actions/util";
import { setNotification } from "../../actions/ui";
import ConfirmationModal from "./ConfirmationModal";
import { changeCatalogue } from "../../actions/orders";

const UpdateCatalogueModal = ({
  util: {
    isCatalogueModalOpen: { bool, data },
    isConfirmation,
  },
  toggleCatalogueModal,
  selected_order,
  toggleConfirmation,
  setNotification,
  auth: { user },
  selectedCountriesForDropdown
}) => {
  let [order, setOrder] = useState([]);
  let [orderItem, setOrderItem] = useState([]);

  useEffect(() => {
    setOrder(data.items);
    if (data.items) {
      setOrderItem(
        data.items.map((item, index) => (
          {
            id: index + 1,
            qty: item.qty,
            brandName: item.brandName,
            medicationId: item.medicationId,
            medication_id: item._id,
            status: item.status,
            statusReason: item.statusReason,
            checkStock:
              item.statusReason && item.statusReason === "OOS" ? true : false,
          }))
      );
    }
  }, [data.items]);

  function isValuesUpdated() {
    if(data.items) {
      let isUpdated = true
      data.items.forEach((item, originalIndex) => {
        if (isUpdated) {
          const checkStock = item.statusReason && item.statusReason === "OOS" ? true : false
          orderItem.forEach((updatedItem, updatedIndex) => {
            if (originalIndex === updatedIndex) {
              if ((item.qty !== updatedItem.qty) || (checkStock !== updatedItem.checkStock)){
                isUpdated = false
                return false
              }
            }
          })
        }
      })
      return isUpdated
    } else {
      return true
    }
  }

  return (
    <ReactModal
      style={{
        overlay: {
          backgroundColor: "rgba(0,0,0,0.3)",
        },
        content: {
          background: "none",
          border: "none",
        },
      }}
      isOpen={bool}
      onRequestClose={(e) => {
        toggleCatalogueModal(false);
      }}
      contentLabel="Confirm"
      closeTimeoutMS={200}
      ariaHideApp={false}
    >
      <div className="" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Order</h5>
          </div>
          <div className="modal-body">
            <table className="table checkbox-center">
              <thead>
                <tr>
                  <th scope="col">Out of Stocks</th>
                  <th scope="col">Catalogue Item</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">On Hand</th>
                </tr>
              </thead>
              <tbody>
                {order &&
                  order.map((medicine, index) => {
                    medicine.id = index + 1
                    return (
                      <tr
                        key={index}
                        className={`${orderItem[index].checkStock ? "text-gray" : ""
                          }`}
                      >
                        <td className="center">
                          <input
                            className={`form-check-input `}
                            type="checkbox"
                            id="outofstock"
                            checked={orderItem[index].checkStock}
                            onChange={(e) =>
                              setOrderItem(
                                orderItem.map((item, index) => {
                                  if (
                                    medicine.id === item.id
                                  ) {
                                    if (e.target.checked === true) {
                                      item.status = "REJ";
                                      item.statusReason = "OOS";
                                    } else {
                                      item.status = null;
                                      item.statusReason = null;
                                    }
                                    item.checkStock = e.target.checked;
                                  }
                                  return item;
                                })
                              )
                            }
                          />
                        </td>
                        <td>{medicine.brandName}</td>
                        <td>{medicine.qty}</td>
                        <td>
                          <input
                            disabled={orderItem[index].checkStock}
                            type="number"
                            className="form-control"
                            min={1}
                            value={orderItem[index].qty}
                            name={medicine.brandName}
                            onKeyPress={(e) => e.preventDefault()}
                            onChange={(e) =>
                              setOrderItem(
                                orderItem.map((item, secondIndex) => {
                                  if (
                                    medicine.id === item.id
                                  ){
                                    if(!(e.target.value === '')){
                                      if (!(isNaN(parseInt(e.target.value)))){
                                        item.qty = parseInt(e.target.value);
                                      }
                                    }
                                  }
                                  return item;
                                })
                              )
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default btn-cancel"
              data-dismiss="modal"
              onClick={(e) => toggleCatalogueModal(false)}
            >
              <label>Close</label>
            </button>

            <button
              disabled = { isValuesUpdated() }
              type="button"
              className="button--processing button--default"
              onClick={(e) => toggleConfirmation(true)}
            >
              Confirm
            </button>
            {isConfirmation && (
              <ConfirmationModal
                isReason={false}
                title={`Catalogue Update`}
                message={`Are you sure you want to update ${data.patientName} medicines order?`}
                toggleConfirmation={toggleConfirmation}
                clickEvent={(e) => {
                  // fulfilled order
                  selected_order.items = order.map((item, index) => {
                    if (item.qty !== orderItem[index].qty) {
                      if (item.lastUpdateTime !== undefined) {
                        item.lastUpdateTime.unshift(new Date());
                      } else {
                        item.lastUpdateTime = [new Date()];
                      }
                    }

                    if (item.qtyoriginal === undefined) {
                      item.qtyoriginal = item.qty;
                    }
                    item.qty = orderItem[index].qty;
                    item.status = orderItem[index].status;
                    item.statusReason = orderItem[index].statusReason;

                    return item;
                  });

                  selected_order.pastStatuses = [
                    ...selected_order.pastStatuses,
                    selected_order.currentStatus,
                  ];

                  selected_order.currentStatus = {
                    status: selected_order.currentStatus.status,
                    statusDate: new Date().toISOString(),
                    username: selected_order.currentStatus.username,
                    reason: "Update Catalogue",
                  };

                  changeCatalogue(selected_order, user, selectedCountriesForDropdown);
                  toggleCatalogueModal(false);
                  toggleConfirmation(false);
                  setNotification(
                    true,
                    "ACCEPTED",
                    `${selected_order.patientName} has successfully update catalogue`
                  );

                  setTimeout(() => {
                    setNotification(false);
                  }, 5000);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

UpdateCatalogueModal.propTypes = {
  util: PropTypes.object.isRequired,
  toggleCatalogueModal: PropTypes.func,
  toggleConfirmation: PropTypes.func,
  setNotification: PropTypes.func,
  createNewOrder: PropTypes.func,
  selected_order: PropTypes.object,
};

const mapStateToProps = (state) => ({
  util: state.util,
  selected_order: state.order,
  auth: state.auth,
  selectedCountriesForDropdown: state.suppliers.selectedCountriesForDropdown
});

export default connect(mapStateToProps, {
  toggleCatalogueModal,
  toggleConfirmation,
  setNotification,
})(UpdateCatalogueModal);
