import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const OrderHistory = ({ selected_order }) => {
  const renderBadge = (status) => {
    switch (status.toUpperCase()) {
      case "PENDING":
        return "badge-alert";
      case "NOT_DELIVERED":
      case "REJECTED":
        return "badge-error";
      case "ACCEPTED":
        return "badge-notice";
      case "DISPATCHED":
        return "badge-success";
      case "RECEIVED":
        return "badge-received";
      case "PICKED_UP":
        return "badge-picked";
      case "DELIVERED":
        return "badge-delivered";
      case "RETURNED":
        return "badge-alert";
      default:
        return "badge-default";
    };
  };

  return (
    <div className="modal__subtitle history__order">
      <span>
        <ul>
          {selected_order.pastStatuses &&
            selected_order.pastStatuses
              .sort((a, b) => b.statusDate - a.statusDate)
              .map((status, index) => {
                return (
                  <li key={index} className="list-icon history-badge">
                    <div className="list-icon-label">
                      <i
                        className={
                          "badge fas fa-circle " + renderBadge(status.status)
                        }
                      ></i>
                      <span>{status.status}</span> <br />
                    </div>
                    <div className="list-icon-label">
                      <i className="far fa-calendar-alt"></i>
                      {moment(status.statusDate).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                      <br />
                    </div>
                    <div className="list-icon-label">
                      <i className="fas fa-user-edit"></i>
                      <span>{status.username === 'undefined undefined undefined' ? '--' : status.username}</span> <br />
                    </div>
                    {status.reason !== undefined && (
                      <div className="list-icon-label">
                        <i className="fas fa-hand-paper"></i>
                        <span>{status.reason ? status.reason : ''}</span> <br />
                      </div>
                    )}
                    {status.reason === "Update Catalogue" && (
                      <div className="list-icon-label sub-list-icon">
                        <div>
                          <i className="fas fa-shopping-cart" />
                          <span>Order Items</span> <br />
                        </div>
                        <div className="orderline-history">
                          <ul>
                            <li className="flex">
                              <div>Item</div>
                              <div>Original Count</div>
                            </li>
                            {selected_order.items.map((item, index) => (
                              <li className="flex" key={index}>
                                <div>{item.brandName}</div>
                                <div>{item.qtyoriginal}</div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </li>
                );
              })}
          {selected_order.currentStatus && (
            <li className="list-icon history-badge">
              <div className="list-icon-label">
                <i
                  className={
                    "badge fas fa-circle " +
                    renderBadge(selected_order.currentStatus.status)
                  }
                ></i>
                <span>{selected_order.currentStatus.status}</span> <br />
              </div>
              <div className="list-icon-label">
                <i className="far fa-calendar-alt"></i>
                {moment(selected_order.currentStatus.statusDate).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
                <br />
              </div>
              <div className="list-icon-label">
                <i className="fas fa-user-edit"></i>
                <span>{selected_order.currentStatus.username === 'null null' ? '--' : selected_order.currentStatus.username}</span>{" "}
                <br />
              </div>
              {selected_order.currentStatus.reason === "Update Catalogue" && (
                <div className="list-icon-label sub-list-icon">
                  <div>
                    <i className="fas fa-shopping-cart" />
                    <span>Order Items</span> <br />
                  </div>
                  <div className="orderline-history">
                    <ul>
                      <li className="flex">
                        <div>Item</div>
                        <div>Original Count</div>
                      </li>
                      {selected_order.items.map((item, index) => (
                        <li className="flex" key={index}>
                          <div>{item.brandName}</div>
                          <div>{item.qtyoriginal}</div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </li>
          )}
        </ul>
      </span>
    </div>
  );
};

OrderHistory.propTypes = {
  selected_order: PropTypes.object.isRequired,
};

export default OrderHistory;
