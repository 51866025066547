import React, { Fragment, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { startLogin } from '../actions'
import { Redirect } from 'react-router-dom'
import {
  authenticateSession,
  dispatchLoaderMessage,
} from '../actions/sso.login'
import { CrossStorageClient } from 'cross-storage'
import Loading from '../components/util/Loading'
import { AUTH_CLIENT_HOST_URL } from '../constants/centralizedAuthHostUrls'
import { Notification } from '../components'

const LoginPage = ({
  error,
  isAuthenticated,
  startLogin,
  authenticateSession,
  dispatchLoaderMessage,
  cur_process: loadMessage,
  is_notification_open,
  notification,
}) => {
  useEffect(() => {
    const validateSession = async () => {
      if (!isAuthenticated) {
        let msg = 'Validating Session, Please wait....'

        await dispatchLoaderMessage(msg)
        msg = ''
        const storage = new CrossStorageClient(AUTH_CLIENT_HOST_URL, {
          timeout: 10000,
          frameId: 'storageFrame',
        })
        let token = null
        await storage
          .onConnect()
          .then(async () => {
            const session = JSON.parse(await storage.get('session'))
            const isSessionActive = session.logistics.isActive
            if (isSessionActive) {
              token = session.token
              const userId = session.userId
              const appId = session.logistics.appId
              if (token) {
                await authenticateSession(token, userId, appId)
                dispatchLoaderMessage(msg)
              }
            } else {
              dispatchLoaderMessage(msg)
              window.location.replace(AUTH_CLIENT_HOST_URL)
            }
          })
          .catch(function (err) {
            console.log(err)
            dispatchLoaderMessage(msg)
            window.location.replace(AUTH_CLIENT_HOST_URL)
          })
      }
    }

    validateSession()
  }, [isAuthenticated, authenticateSession, dispatchLoaderMessage])

  if (isAuthenticated) {
    return <Redirect to='/dashboard' />
  }
  return (
    <Fragment>
      {
        loadMessage !== '' ? <Loading message={loadMessage} /> : ''
        //  (
        //   <Fragment>
        //     <div className='login__layout'>
        //       <div className='login__body'>
        //         <div className='login__body__form'>
        //           <h4 className='login__body__formheader center'>
        //             <p className='text-blur'>Welcome to</p>
        //             <img src='./images/reach52_logistics.png' alt='logo' />
        //           </h4>
        //           <div className='login__body__formbody'>
        //             <Login />
        //           </div>
        //         </div>
        //         <p className='text-white'>
        //           Dispergo.{process.env.REACT_APP_AWHDISPERGO_VERSION}
        //         </p>
        //       </div>
        //     </div>
        //   </Fragment>
        // )
      }
      {is_notification_open && <Notification notification={notification} />}
    </Fragment>
  )
}

const mapStateToProps = (state, props) => ({
  error: state.auth.error,
  isAuthenticated: state.auth.isAuthenticated,
  cur_process: state.cur_process,
  is_notification_open: state.ui.notification.show,
  notification: state.ui.notification,
})

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    { startLogin, authenticateSession, dispatchLoaderMessage },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
