import setAuthToken from '../lib/setAuthToken.lib'
import { AT_LOGIN } from './types'
import { setProcess } from './cur_process'
import {
  AUTH_SESSION_API_URL,
} from '../constants/centralizedAuthHostUrls'
import api from '../lib/api.lib'
import apiNotification from '../lib/api.notification.lib'
const login = (uid, isAuthenticated, error = '', user) => ({
  type: AT_LOGIN,
  uid,
  isAuthenticated,
  error,
  user,
})

export const authenticateSession =
  (token, userId, appId) => async (dispatch) => {
    try {
      const res = await api.post(AUTH_SESSION_API_URL, {
        token,
        userId,
        appId,
      })
      console.log('res', res)
      if (res.status === 200) {
        const { username } = res.data.data.user
        setAuthToken(res.data.data.token, res.data.data.user)
        await dispatch(login(username, true, '', res.data.data.user))
      } else {
        dispatch(login('', false, 'Error encountered. Please try again.'))
        localStorage.removeItem('user')
        localStorage.removeItem('token')
      }
    } catch (error) {
      apiNotification(error, dispatch)
    }
  }

export const dispatchLoaderMessage = (message) => async (dispatch) => {
  await dispatch(setProcess(message))
}
