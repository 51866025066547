import {
  SET_PLANSOLDDURATION,
  SET_INSURANCEDASHBOARD,
  SET_DATERANGE,
  UPDATE_INSURANCEDASHBOARD,
  SET_SELECTLINEGRAPHOPTION,
  SET_UPDATEDPLAN,
  RELOAD_PLAN,
  SET_INSURER_LIST,
  SET_INSURER_DROPDOWN_DATA,
  SET_COUNTRY_DATA
} from "../actions/types";

const initialState = {
  selectedSoldDuration: false,
  selectedLineGraphOption: "sales",
  dateRange: {
    dateStart: null,
    dateEnd: null,
  },
  loading: true,
  error: {},
  insuranceCompany: {
    insured: [],
    companies: [],
    plans: [],
  },
  soldPlansCount: {
    soldPlans: 0,
    goldPlans: 0,
    silverPlans: 0,
    bronzePlans: 0,
    platinumPlans: 0,
    basicPlans: 0,
    grossSales: 0,
    unit: "",
  },
  insurers:[],
  selectedInsurerForDropdown:[],
  selectedCountry:[]
};

const insuranceDashboard = (plans) => {
  // if theres sales
  if (plans && plans.length !== 0) {
    const activePlans = plans.filter((item) => item.status === "approved");
    let res = {
      grossSales: activePlans
        .map((item) => parseFloat(item.price))
        .reduce((total, add) => (total += parseInt(add.toFixed(3))), 0),
      soldPlans: activePlans.length,
      goldPlans: activePlans.filter((item) => item.title === "gold plan")
        .length,
      silverPlans: activePlans.filter((item) => item.title === "silver plan")
        .length,
      bronzePlans: activePlans.filter((item) => item.title === "bronze plan")
        .length,
      platinumPlans: activePlans.filter(
        (item) => item.title === "platinum plan"
      ).length,
      basicPlans: activePlans.filter((item) => item.title === "basic plan")
        .length,
    };
    res.unit = activePlans.length !== 0 ? "" : "";
    return res;
  } else {
    return {
      soldPlans: 0,
      goldPlans: 0,
      silverPlans: 0,
      bronzePlans: 0,
      platinumPlans: 0,
      basicPlans: 0,
      grossSales: 0,
      unit: "",
    };
  }
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_PLANSOLDDURATION:
      return {
        ...state,
        selectedSoldDuration: payload,
        loading: false,
      };
    case SET_INSURANCEDASHBOARD:
      return {
        ...state,
        insuranceCompany: {
          plans: payload.plans && payload.plans.length > 0 ? payload.plans : []
        },
        soldPlansCount: insuranceDashboard(payload.plans && payload.plans.length > 0 ? payload.plans : []),
        loading: false,
      };
    case UPDATE_INSURANCEDASHBOARD:
      return {
        ...state,
        insuranceCompany: {
          plans: payload.plans,
        },
        soldPlansCount: insuranceDashboard(payload.plans),
        loading: false,
      };
    case SET_DATERANGE:
      return {
        ...state,
        dateRange: {
          dateStart: payload.dateStart,
          dateEnd: payload.dateEnd,
        },
      };
    case SET_SELECTLINEGRAPHOPTION:
      return {
        ...state,
        loading: false,
        selectedLineGraphOption: payload,
      };
    case SET_UPDATEDPLAN:
      return {
        ...state,
        insuranceCompany: payload,
        loading: false,
      };
    case RELOAD_PLAN:
      return {
        ...state,
        insuranceCompany: {
          insured: [],
          companies: [],
          plans: [],
        },
      };
    case SET_INSURER_LIST:
      return {
        ...state,
        insurers: payload,
        loading: false,
      };
    case SET_INSURER_DROPDOWN_DATA:
      return {
        ...state,
        selectedInsurerForDropdown: payload
      };
    case SET_COUNTRY_DATA:
      return {
        ...state,
        selectedCountry: payload
      }
    default:
      return state;
  }
};
