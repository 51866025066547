import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { setViewModal, selectOrder } from "../../actions";
import { connect } from "react-redux";
import { getCurrencySymbol } from '../util/currencySymbols'

const OrdersItem = ({ order, setViewModal, selectOrder }) => {
  const renderBadge = (status) => {
    switch (status.toUpperCase()) {
      case "PENDING":
        return "badge-alert";
      case "NOT_DELIVERED":
      case "REJECTED":
        return "badge-error";
      case "ACCEPTED":
        return "badge-notice";
      case "DISPATCHED":
        return "badge-success";
      case "RECEIVED":
        return "badge-received";
      case "PICKED_UP":
        return "badge-picked";
      case "DELIVERED":
        return "badge-delivered";
      case "RETURNED":
        return "badge-alert";
      default:
        return "badge-default";
    }
  };
  
var getIsoVal = order.isoCurrency; //to get iso code of selected country e.g 'USD, KHR, PHP, INR'
const isoCodeLanguage = getCurrencySymbol(getIsoVal);
let currencyOutput = 'en-US';
let numberFormatOptions = {
  style: 'currency',
  currency: isoCodeLanguage,
  locale: 'en-US'
};

switch (isoCodeLanguage) {  
  case 'PHP':
    currencyOutput = 'en-PH' //Philippines
    numberFormatOptions.locale = 'en-PH';
    break;
  case 'IDR':
    currencyOutput = 'id-ID' //Indonesia
    numberFormatOptions.locale = 'id-ID';
    break;
  case 'INR':
    currencyOutput = 'ne-IN'; //India
    numberFormatOptions.locale = 'ne-IN';
    break;
  case 'ZAR':
    currencyOutput = 'en-ZA'; //South Aftrica
    numberFormatOptions.locale = 'en-ZA';
    break;
  case 'KES':
    currencyOutput = 'sw-KE'; //Kenya
    numberFormatOptions.locale = 'sw-KE';
    break;
  case 'KHR':
    currencyOutput = 'km-KH'; //Cambodia
    numberFormatOptions.locale = 'km-KH';
    break;
  default:
}

  return (
    <div
      className="card"
      onClick={() => {
        selectOrder(order);
        setViewModal(true);
      }}
    >
      {/* <div className="card-body">
        <h5 className="card-heading-title">{order.patientName ? order.patientName : ''}</h5>
        <small className="card-text text-muted">{order.patientAddress ? order.patientAddress : ''}</small>
      </div> */}
      <ul className="list-group list-group-flush">
        <li>
          <div className="card-body">
            <h5 className="card-heading-title">{order.patientName ? order.patientName : ''}</h5>
          </div>
        </li>
        <li title="Address" className="list-group-item">
          <i className="fas fa-map-marker-alt"></i>
          {order.patientAddress ? order.patientAddress : ''}
        </li>
        <li title="Date" className="list-group-item">
          <i className="far fa-calendar-alt" />
          {moment(order.currentStatus && order.currentStatus.statusDate).format(
            "MMM DD, YYYY, h:mma"
          )}
        </li>
        <li className="list-group-item">
        </li>
        <li title="Agent" className="list-group-item">
          <i className="fas fa-user-edit" />
          {/* {order.currentStatus.userDisplayName === 'undefined undefined undefined' || order.currentStatus.userDisplayName === 'null null' ? '--' : order.currentStatus.username} */}
          {order.currentStatus.userId ? order.currentStatus.userId : '--'}
        </li>
        <li title="Prescription number" className="list-group-item">
          <i className="fas fa-user-md" /> 
          {order.prescriptionNumber ? order.prescriptionNumber : '--'}
        </li>
        {/* <li title="Physician" className="list-group-item"> */}
        <li title="Order Value" className="list-group-item">
          <i className="fas fa-prescription" />
          <span className="lh-1">
            {/* {order.physicianName !== "null null" ? order.physicianName : '--'} <br /> */}
            {order.totalPayable?.$numberDecimal ? new Intl.NumberFormat(currencyOutput, numberFormatOptions).format(order.totalPayable.$numberDecimal) : '--'}
            {/* {order.totalPayable ? order.totalPayable.$numberDecimal ? order.totalPayable.$numberDecimal : '--' : '--'} <br /> */}
            <small className="text-muted">{order.physicianLicenseNumber === null ? order.physicianLicenseNumber : ''}</small>
          </span>
        </li>
        <li title="Supplier" className="list-group-item">
          <i className="fas fa-user-alt"></i>
          {order.items[0].supplier}
        </li>
        <li className="list-group-item">
        </li>
        <li title="Order status" className="list-group-item">
          <span
            className={"badge " + renderBadge(order.currentStatus && order.currentStatus.status)}
          />
          {order.currentStatus && order.currentStatus.status}
        </li>
      </ul>
    </div>
  );
};

OrdersItem.propTypes = {
  selectOrder: PropTypes.func,
  setViewModal: PropTypes.func,
  order: PropTypes.object.isRequired,
};

export default connect(null, { selectOrder, setViewModal })(OrdersItem);
