import React, { useState } from "react";

const ConfirmationModal = ({
  message,
  toggleConfirmation,
  title,
  clickEvent,
  isReason = false,
  status = "active",
}) => {
  const [reason, setReason] = useState({
    reason: "Invalid Transaction – No Acknowledgment Receipt",
    reasonCode: "rc1",
    status,
  });

  const selectReason = (reasonCode) => {
    let reasonString;
    switch (reasonCode) {
      case "rc1":
        reasonString = "Invalid Transaction – No Acknowledgment Receipt";
        break;
      case "rc2":
        reasonString = "Invalid Transaction – Cancelled by Insured";
        break;
      default:
        break;
    }
    setReason({
      reason: reasonString,
      reasonCode,
      status,
    });
  };
  return (
    <div className="modal-prompt open">
      <div className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>{message}</p>
              {isReason && (
                <div className="form-group">
                  <label htmlFor="sel1" className="text-gray">
                    Select Reason:
                  </label>
                  <select
                    onChange={(e) => selectReason(e.target.value)}
                    className="browser-default custom-select"
                  >
                    <option value="rc1">
                      Invalid Transaction – No Acknowledgment Receipt
                    </option>
                    <option value="rc2">
                      Invalid Transaction – Cancelled by Insured
                    </option>
                  </select>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                onClick={(e) => toggleConfirmation(false)}
                className="button--default btn-secondary"
              >
                Close
              </button>
              <button
                className="button--primary button--default"
                onClick={(e) => clickEvent(reason)}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmationModal.propTypes = {};

export default ConfirmationModal;
