export const GET_AGENTS = "GET_AGENTS";
export const AT_LOGIN = "AT_LOGIN";
export const AT_LOGOUT = "AT_LOGOUT";
export const AT_SET_PROCESS = "AT_SET_PROCESS";

// insurance
export const SET_PLANSOLDDURATION = "SET_PLANSOLDDURATION";
export const SET_INSURANCEDASHBOARD = "SET_INSURANCEDASHBOARD";
export const SET_NEWSOLDPLAN = "SET_NEWSOLDPLAN";
export const SET_DATERANGE = "SET_DATERANGE";
export const UPDATE_INSURANCEDASHBOARD = "UPDATE_INSURANCEDASHBOARD";
export const SET_SELECTLINEGRAPHOPTION = "SET_SELECTLINEGRAPHOPTION";
export const SET_UPDATEDPLAN = "SET_UPDATEDPLAN";
export const RELOAD_PLAN = "RELOAD_PLAN";
export const SET_INSURER_LIST = "SET_INSURER_LIST";
export const SET_INSURER_DROPDOWN_DATA = "SET_INSURER_DROPDOWN_DATA";
export const SET_COUNTRY_DATA = 'SET_COUNTRY_DATA'

// util
export const INSURANCEMODAL = "INSURANCEMODAL";
export const AT_SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_CONFIRMATION = "SET_CONFIRMATION";
export const SET_CATALOGUE_MODAL = "SET_CATALOGUE_MODAL";
export const SET_COUNTRY_DROPDOWN_DATA = "SET_COUNTRY_DROPDOWN_DATA";

// medication
export const SET_CATALOGUE = "SET_CATALOGUE";

//orders
export const SET_SUPPLIER_LIST = 'SET_SUPPLIER_LIST'
export const SET_SUPPLIER_DROPDOWN_DATA = 'SET_SUPPLIER_DROPDOWN_DATA'
