import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import { toggleInsuranceModal, toggleConfirmation } from "../../actions/util";
import {
  updateInsurancePlan,
  approveInsurance,
  issueInsuranceDate,
} from "../../actions/insurer";
import { setNotification } from "../../actions/ui";
import ConfirmationModal from "./ConfirmationModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-image-gallery/styles/css/image-gallery.css";
import MyImageGallery from "./MyImageGallery";

const Modal = ({
  util: {
    isInsuranceModalOpen: { data, bool },
    isConfirmation,
  },
  auth: { user },
  insurer: { insuranceCompany, dateRange, selectedCountry },
  toggleInsuranceModal,
  updateInsurancePlan,
  approveInsurance,
  setNotification,
  toggleConfirmation,
  issueInsuranceDate,
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [certificateNumber, setCertificateNumber] = useState("");
  const [approvalStatus, setApprovalStatus] = useState("");

  return (
    <div>
      <ReactModal
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.3)",
          },
        }}
        isOpen={bool}
        onRequestClose={(e) => toggleInsuranceModal(false)}
        contentLabel="Confirm"
        closeTimeoutMS={200}
        ariaHideApp={false}
        className="modal"
      >
        <div className="images-div">
          <MyImageGallery data = {data} />
        </div>
        <div className="modal__content">
          <p
            className="text-muted modal--close"
            onClick={(e) => toggleInsuranceModal(false)}
          >
            close
          </p>
          <h5 className="modal__title">
            {data.residentName && data.residentName.map((name, index) => {
              return <div key={index}>
                <span className="text-reach" style={{ display: 'flex', marginRight: '10px' }}>
                  <i className="fas fa-user-alt" />
                  {name}</span>
                {data.gender[index]} {data.age[index]} yrs. old <br />
              </div>
            })}
          </h5>
          {user.permissions[0] &&
            data && user.permissions[0].associate === "forte"
            ? (
              <div className="modal__subtitle">
                <i className="fas fa-question"></i>
                <span>
                  <span className="text-muted">Questionnaire</span>
                  <ul className="bulleted-list">
                    {data.questions && data.questions.length &&
                      data.questions.map((item, index) => {
                        return (
                          <li key={index}>
                            <div>{item.questionCode}</div>
                            <ul>
                              <li>
                                <i>{item.answer}</i>
                              </li>
                            </ul>
                          </li>
                        );
                      })}
                  </ul>
                </span>
              </div>
            ) : (
              ""
            )}
          {
            user.permissions[0].role === "underwriter" && (
              <Fragment>
                {data.status === "requested" && (
                  <div className="modal__subtitle modal__button--border">
                    <button
                      onClick={() => {
                        setApprovalStatus("declined");
                        toggleConfirmation(true);
                      }}
                      className="button--default btn-secondary"
                    >
                      Decline
                    </button>
                    <div
                      onClick={() => {
                        setApprovalStatus("approved");
                        toggleConfirmation(true);
                      }}
                      className="button--default button-inline button--primary"
                    >
                      Approve
                    </div>

                    {isConfirmation && (
                      <ConfirmationModal
                        clickEvent={() => {
                          approveInsurance(
                            data.id,
                            insuranceCompany,
                            approvalStatus,
                            user,
                            selectedCountry
                          );
                          toggleInsuranceModal(false);
                          let confirmationClass =
                            approvalStatus === "approved"
                              ? "ACCEPTED"
                              : "REJECTED";
                          setNotification(
                            true,
                            `${confirmationClass}`,
                            `${data.residentName} ${data.title} has successfully ${approvalStatus}`
                          );
                          setTimeout(() => {
                            setNotification(false);
                          }, 5000);
                          toggleConfirmation(false);
                        }}
                        isReason={false}
                        title={`Insurance ${approvalStatus}`}
                        message={`Are you sure you want to ${approvalStatus} ${data.residentName && data.residentName[0] ? data.residentName[0] : data.residentName[0]}?`}
                        toggleConfirmation={toggleConfirmation}
                      />
                    )}
                  </div>
                )}
              </Fragment>
            )}
          <div className="modal__subtitle">
            <i className="fas fa-hashtag"></i>
            <span>
              <span className="text-muted">Certificate Number</span>
              {data["certificateNo"]}
              {user.permissions[0] &&
                user.permissions[0].associate === "forte" &&
                data.status === "pending issuance" &&
                user.permissions[0].role === "underwriter" && (
                  <label className="">
                    <input
                      className="focus"
                      type="text"
                      name="certificate"
                      placeholder="Certificate Number"
                      value={certificateNumber}
                      onChange={(e) => setCertificateNumber(e.target.value)}
                    />
                    {/* <i className="fas fa-unlock" /> */}
                  </label>
                )}
            </span>
          </div>
          <div className="modal__subtitle">
            <i className="fas fa-hourglass-half"></i>
            <span className="modal-datapicker">
              <span className="text-muted">Policy Duration</span>
              {data.effectiveDate}
              {user.permissions[0] &&
                data.status === "pending issuance" &&
                user.permissions[0].role === "underwriter" && (
                  <Fragment>
                    <ul className="list-group list-group-horizontal">
                      <div className="input-datePicker">
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          selected={startDate}
                          onChange={(date) => {
                            setStartDate(date);
                          }}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          placeholderText="Start Date"
                          withPortal
                          utcOffset={0}
                        />
                        <i className="fas fa-calendar-alt"></i>
                      </div>
                      <div className="input-datePicker">
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          selected={endDate}
                          onChange={(date) => {
                            setEndDate(date);
                          }}
                          placeholderText="End Date"
                          selectsEnd
                          endDate={endDate}
                          minDate={startDate}
                          withPortal
                          utcOffset={0}
                        />
                        <i className="fas fa-calendar-alt"></i>
                      </div>
                    </ul>
                    {startDate !== null &&
                      endDate !== null &&
                      certificateNumber.length !== 0 && (
                        <button
                          className="button--default button-inline button--primary"
                          onClick={(e) => toggleConfirmation(true)}
                        >
                          Save
                        </button>
                      )}
                    {isConfirmation && (
                      <ConfirmationModal
                        clickEvent={() => {
                          issueInsuranceDate(
                            { startDate, endDate, certificateNumber },
                            data,
                            insuranceCompany,
                            user
                          );
                          setStartDate(null);
                          setEndDate(null);
                          toggleInsuranceModal(false);
                          setNotification(
                            true,
                            "ACCEPTED",
                            `${data.residentName} ${data.title} has successfully activate`
                          );
                          setTimeout(() => {
                            setNotification(false);
                          }, 5000);
                          toggleConfirmation(false);
                        }}
                        isReason={false}
                        title={"Insurance Issuance Date"}
                        message={`Are you sure you want to activate ${data.residentName} ${data.title}?`}
                        toggleConfirmation={toggleConfirmation}
                      />
                    )}
                  </Fragment>
                )}
              {data.status === "active" && (
                <Fragment>{data.effectiveDate}</Fragment>
              )}
            </span>
          </div>
          <div className="modal__subtitle">
            <i className="fas fa-info"></i>
            <span>
              <span className="text-muted">Status</span>
              {data.status}
            </span>
          </div>
          {/* {data.status === "cancelled" && (
            <div className="modal__subtitle">
              <i className="fas fa-comment"></i>
              <span>
                <span className="text-muted">Reason</span>
                {data["insurer.qualification"].denial_reason}
              </span>
            </div>
          )} */}
          <div className="modal__subtitle">
            <i className="fas fa-landmark"></i>
            <span>
              <span className="text-muted">Civil Status</span>
              {/* {data.civilStatus} */}
              {data.maritalStatus}
            </span>
          </div>
          <div className="modal__subtitle">
            <i className="fas fa-birthday-cake"></i>
            <span>
              <span className="text-muted">Date of Birth</span>
              {data.dateOfBirth}
            </span>
          </div>
          <div className="modal__subtitle">
            <i className="fas fa-building"></i>
            <span>
              <span className="text-muted">Insurer</span>
              {data["insuranceSupplier.suppName"]}
            </span>
          </div>
          <div className="modal__subtitle">
            <i className="fas fa-file-alt"></i>
            <span>
              <span className="text-muted">Insurance Plan</span>
              {data.title}
            </span>
          </div>
          <div className="modal__subtitle">
            <i className="fas fa-money-bill-wave-alt"></i>
            <span>
              <span className="text-muted">Price</span>
              {data.price}
            </span>
          </div>
          {user.permissions[0] &&
            user.permissions[0].associate !== "forte" &&
            data.status === "active" && (
              <div className="modal__footer">
                <span className="text-gray">
                  Cancelation of invalid purchased insurance
                </span>
                <button
                  className="btn btn-secondary"
                  onClick={(e) => toggleConfirmation(true)}
                >
                  Cancel
                </button>
                {isConfirmation && (
                  <ConfirmationModal
                    clickEvent={(reason) => {
                      updateInsurancePlan(
                        data.id,
                        insuranceCompany,
                        reason,
                        dateRange,
                        user,
                        selectedCountry
                      );
                      toggleInsuranceModal(false);
                      setNotification(
                        true,
                        reason.status,
                        `${data.residentName} ${data.title} has successfully ${reason.status}`
                      );
                      setTimeout(() => {
                        setNotification(false);
                      }, 5000);
                      toggleConfirmation(false);
                    }}
                    status="cancelled"
                    isReason={true}
                    title={"Insurance Plan Cancellation"}
                    message={`Are you sure you want to cancel ${data.residentName} ${data.title}?`}
                    toggleConfirmation={toggleConfirmation}
                  />
                )}
              </div>
            )}
        </div>
      </ReactModal>
    </div >
  );
};

Modal.propTypes = {
  toggleInsuranceModal: PropTypes.func.isRequired,
  util: PropTypes.object.isRequired,
  updateInsurancePlan: PropTypes.func.isRequired,
  approveInsurance: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  insurer: PropTypes.object.isRequired,
  toggleConfirmation: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  util: state.util,
  insurer: state.insurer,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  toggleInsuranceModal,
  updateInsurancePlan,
  setNotification,
  toggleConfirmation,
  approveInsurance,
  issueInsuranceDate,
})(Modal);
