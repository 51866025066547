import React, { useEffect, useState } from 'react'
import OrdersTableRow from './OrdersTableRow'
import PropTypes from 'prop-types'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  TableHead,
} from '@material-ui/core'
import TablePaginationActions from './TablePaginationAction'

const OrdersTable = ({ orders }) => {
  const [listSpecificOrders, setListSpecificOrders] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  useEffect(() => {
    setListSpecificOrders(orders.flatMap((object) => object.orders))
  }, [orders, setListSpecificOrders])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  if (listSpecificOrders.length < page * rowsPerPage) {
    setPage(0)
  }

  return (
    <div className='table-responsive order-list-table'>
      <TableContainer>
        <Table
          className='table list-table'
          aria-label='custom pagination table'
        >
          <TableHead>
            <TableRow className='orderlistHead'>
              <TableCell>Name</TableCell>
              <TableCell>Order Date</TableCell>
              <TableCell>Agent</TableCell>
              <TableCell>Rx</TableCell>
              <TableCell>Order Value</TableCell>
              <TableCell>Supplier Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listSpecificOrders.length ? (
              (rowsPerPage > 0
                ? listSpecificOrders.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : listSpecificOrders
              ).map((row, i) => <OrdersTableRow key={'row-' + i} order={row} />)
            ) : (
              <TableRow>
                <TableCell align='center' colSpan={7}>
                  No records found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                colSpan={8}
                count={listSpecificOrders.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  )
}
OrdersTable.propTypes = {
  orders: PropTypes.array.isRequired,
}

export default OrdersTable
