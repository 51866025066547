import { AT_SET_PROCESS } from '../actions';

export const defaultProcessState = '';

export default (state = defaultProcessState, action) => {
  switch(action.type) {
    case AT_SET_PROCESS:
      return action.payload.cur_process;
    default:
      return state;
  }
};