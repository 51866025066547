import {getSuppliers} from '../../src/Apis/MedsOrderApis/ordersApi'
import apiNotification from '../lib/api.notification.lib'
import { SET_SUPPLIER_DROPDOWN_DATA, SET_SUPPLIER_LIST, SET_COUNTRY_DROPDOWN_DATA } from './types'
import { initOrders, setOrders } from '../actions'

export const getSupplierData = (country, isCountryChanged, selectedSuppliers) => async (dispatch, getState) => {
  try {
    const region = country.includes('idn') ? 'IDN' : 'IND'
    await dispatch(setSupplierDropdownData([]))
    const res = await getSuppliers(region, country)
    if (res && res.length > 0) {
      await dispatch({
        type: SET_SUPPLIER_LIST,
        payload: res,
      })
      if (isCountryChanged) {
        let supplierCodes = []
        const user = getState().auth.user
        if (user.permissions[0].associate === "All") {
          res.map((obj) => {
            supplierCodes.push(obj.supplierCode)
            return obj
          })
        } else {
          res.map((obj) => {
            if (selectedSuppliers.includes(obj.supplierCode)) {
              supplierCodes.push(obj.supplierCode)
            }
            return obj
          })
        }
        await dispatch(initOrders(supplierCodes, country))
      }
    } else {
      await dispatch({
        type: SET_SUPPLIER_LIST,
        payload: []
      })
      await dispatch(setOrders())
      apiNotification({message:'Supplier & Order Data not found for selected country'}, dispatch)
    }
  } catch (error) {
    apiNotification(error, dispatch)
  }
}

export const setSupplierDropdownData = (suppliers) => async (dispatch) => {
  await dispatch({
    type: SET_SUPPLIER_DROPDOWN_DATA,
    payload: suppliers
  })
}

export const setCountriesForDropdown = (countries) => async (dispatch) => {
  await dispatch({
    type: SET_COUNTRY_DROPDOWN_DATA,
    payload: countries
  })
}
  