export const AT_SET_TEXT_FILTER = "SET_TEXT_FILTER";
export const AT_SET_STATUS_FILTER = "SET_STATUS_FILTER";
export const AT_SET_DATERANGE = "SET_DATERANGE";
export const AT_SET_ORDER_TYPE_FILTER = 'AT_SET_ORDER_TYPE_FILTER';

export const setTextFilter = text => ({
  type: AT_SET_TEXT_FILTER,
  payload: { text }
});

export const setStatusFilter = status => ({
  type: AT_SET_STATUS_FILTER,
  payload: { status }
});

export const setOrderTypeFilter = orderType => ({
  type: AT_SET_ORDER_TYPE_FILTER,
  payload: { orderType }
});

export const setStartDateRangeOrder = (
  dateStart,
  dateEnd
) => async (dispatch) => {
  if (dateStart !== null && dateEnd !== null) {
    dispatch({
      type: AT_SET_DATERANGE,
      payload: {
        dateStart: dateStart,
        dateEnd: dateEnd,
      },
    });
  }
};
