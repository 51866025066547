const helpMenu = {
  label: 'About',
  panelId: 'about',
  description: 'reach52 Logistics web application is divided into Financial Services & Product Distribution ' +
  'web modules. The Financial Services module is used to manage/track Insurance-related orders, whereas ' +
  'Product Distribution is for managing/tracking medicine supplier orders.',
  options: [
    {
      label: 'Financial Services',
      value: 'insurance',
      description: `reach52 logistics financial services is an Insurance order management application, ` +
      `used to manage reach52 access users' insurance orders from various insurance providers. Users with ` + 
      `role insurance can access all the module features.`
    },
    {
      label: 'Product Distribution',
      value: 'pharmacist',
      description: 'reach52 logistics distribution service is a delivery management application, this app ' +
      'is used to track, manage & process Medicine orders placed by reach52 access (mobile app) users from ' +
      'various medicine suppliers. A user having a user role pharmacist can access all the features of this ' +
      'application.'
    }
  ]
}

export default helpMenu
