import {
  AT_SET_ORDERS,
  AT_SET_ORDER
} from '../actions';

export const defaultOrdersstate = {
  '12121212141212141': {
    "type": "order",
    "currentStatus": {
      "status": "ACCEPTED",
      "statusDate": "2018-02-03T23:12:12+00:00"
    },
    "pastStatuses": [
      {
        "status": "PENDING",
        "statusDate": "2018-02-03T23:12:12+00:00"
      },
      {
        "status": "DISPATCHED",
        "statusDate": "2018-02-03T23:12:12+00:00"
      }
    ],
    "physicianLicenseNumber": "00001",
    "residentId": "00112233-4455-6677-8899-aabbccddeeff",
    "patientName": "dela Cruz, Juan",
    "patientGender": "Male",
    "patientAge": 42,
    "patientAddress": "Cuartero Iloilo, Philippines",
    "items": [
      {
        "medicationId": "00112233-4455-6677-8899-aabbccddeefd",
        "price": 10.00,
        "quantity": 3
      },
      {
        "medicationId": "00112233-4455-6677-8899-aabbccddeefe",
        "price": 20.00,
        "quantity": 2
      },
      {
        "medicationId": "00112233-4455-6677-8899-aabbccddeeff",
        "price": 30.00,
        "quantity": 1
      }
    ]
  },
  '324234234234324': {
    "type": "order",
    "currentStatus": {
      "status": "ACCEPTED",
      "statusDate": "2018-02-03T23:12:12+00:00"
    },
    "pastStatuses": [
      {
        "status": "PENDING",
        "statusDate": "2018-02-03T23:12:12+00:00"
      }
    ],
    "physicianLicenseNumber": "00001",
    "residentId": "00112233-4455-6677-8899-aabbccddeeff",
    "patientName": "Rizal, Jose",
    "patientGender": "Male",
    "patientAge": 30,
    "patientAddress": "Cuartero Iloilo, Philippines",
    "items": [
      {
        "medicationId": "00112233-4455-6677-8899-aabbccddeefd",
        "price": 10.00,
        "quantity": 3
      }
    ]
  }
};

export default (state = defaultOrdersstate, action) => {
  switch (action.type) {
    case AT_SET_ORDERS:
      return action.payload.orders;
    case AT_SET_ORDER:
      if (action.payload.order.subOrderID && action.payload.order.id) {
        return {
          ...state,
          [action.payload.order.subOrderID]: action.payload.order
        };
      }
      if (action.payload.order.subOrderID === '' && action.payload.order.id) {
        return {
          ...state,
          [action.payload.order.id]: action.payload.order
        };
      }
    default:
      return state;
  }
};