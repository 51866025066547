import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  setPlanSoldDuration,
  setStartDateRange,
  getInsurerData,
  getInsuranceData,
  setInsurerDropdownData,
  setCountryData
} from "../../actions/insurer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MultiSelect from '@material-ui/core/Select';
import { Checkbox, MenuItem, ListItemText, FormControl} from '@material-ui/core';
import { countries } from '../../constants/countries.static'

const FilterMenu = ({
  insurer: {
    dateRange: { dateStart, dateEnd },
    selectedSoldDuration,
    insuranceCompany: { plans, companies, insured },
    insurers,
    selectedInsurerForDropdown,
    selectedCountry
  },
  setPlanSoldDuration,
  setStartDateRange,
  getInsurerData,
  auth,
  getInsuranceData,
  setInsurerDropdownData,
  setCountryData
}) => {
  const options = []
  useEffect(() => {
    setPlanSoldDuration(selectedSoldDuration, { plans, companies, insured }, {});
    if (auth.user.permissions[0].associate === "All" && options.length === 0) {
      if(selectedCountry.length === 0) {
        getInsurerData([auth.user.country.iso3], false)
      }
    }
    if (selectedInsurerForDropdown.length === 0) {
      setInsurerDropdownData(options.map((val)=>val.value))
    }
    if(insurers.length !== 0 && selectedInsurerForDropdown.length === 0) {
      setInsurerDropdownData(options.map((val)=>val.value))
    }

    if (selectedCountry.length === 0) {
      if (auth.user.country.iso3 === 'all') {
        const filteredCountries = countries.filter((country)=>country.value !== 'idn')
        setCountryData(filteredCountries.map((country)=>country.value))
      } else {
        setCountryData([auth.user.country.iso3])
      }
    }
  }, [setInsurerDropdownData, setPlanSoldDuration, insurers, setCountryData, selectedCountry, auth]);

  if (auth.user.permissions[0].associate === "All") {
    insurers.forEach((obj) => {
      options.push({value: obj.code, label: obj.name.ENG})
    })
  } else {
    auth.user.permissions.forEach((obj) => {
      options.push({value: obj.code, label: obj.associate})
    })
  }

  const onChange = event => {
    const { value } = event.target
    const insurerCodes = []
    value.forEach((obj) => {
      insurerCodes.push(obj)
    })
    setInsurerDropdownData(value)
    if (value.length === 0) {
      if (auth.user.permissions[0].associate === "All") {
        insurerCodes.push('*')
      } else {
        auth.user.permissions.forEach((obj) => {
          insurerCodes.push(obj.code)
        })
      }
    }
    getInsuranceData(insurerCodes, dateStart, dateEnd, selectedCountry)
  }

  const onCountryChange = (event) => {
    const { value } = event.target
    const selectedCountries = []
    if (value.length === 0) {
      const filteredCountries = countries.filter((country)=>country.value !== 'idn')
      setCountryData(filteredCountries.map((country)=>country.value))
      filteredCountries.forEach((obj) => {
        selectedCountries.push(obj.value)
      })
    } else {
      if (value.includes('idn')) {
        if (value[value.length-1] !== 'idn') {
          selectedCountries.push(value[value.length-1])
          setCountryData([value[value.length-1]])
        } else {
          selectedCountries.push('idn')
          setCountryData(['idn'])
        }
      } else {
        value.forEach((obj) => {
          selectedCountries.push(obj)
        })
        setCountryData(value)
      }
    }
    getInsurerData(selectedCountries, true)
  }

  return (
    <div className="insurance-filter">
      <ul className="list-group list-group-horizontal date-range-filter">
        <li
          className={`list-group-item + ${
            selectedSoldDuration ? "is-active" : ""
          }`}
          onClick={(e) =>
            setPlanSoldDuration(!selectedSoldDuration, { plans, companies, insured }, {
              dateStart,
              dateEnd,
            })
          }
        >
          Date Range
        </li>
        <div className="date-picker-insurance input-datePicker">
          <DatePicker
            dateFormat="yyyy-MM-dd"
            disabled={!selectedSoldDuration}
            selected={dateStart}
            onChange={(date) => {
              setStartDateRange(date, dateEnd, { plans, companies, insured });
            }}
            selectsStart
            startDate={dateStart}
            endDate={dateEnd}
            placeholderText="Start Date"
            withPortal
            utcOffset={0}
          />
          <i className="fas fa-calendar-alt"></i>
        </div>
        <div className="date-picker-insurance input-datePicker">
          <DatePicker
            dateFormat="yyyy-MM-dd"
            disabled={!selectedSoldDuration}
            selected={dateEnd}
            onChange={(date) => {
              setStartDateRange(dateStart, date, { plans, companies, insured });
            }}
            placeholderText="End Date"
            selectsEnd
            endDate={dateEnd}
            minDate={dateStart}
            withPortal
            utcOffset={0}
          />
          <i className="fas fa-calendar-alt"></i>
        </div>
      </ul>
      { auth.user.country.iso3 === 'all' ? <div className='form-field insurer-country-filter'>
          <label className='menu-filter-label'>Country</label>
          <FormControl variant="outlined" sx={{ m: 1 }}>
            <MultiSelect
              labelId='app-selectedCountry-checkbox-label'
              id='app-selectedCountry-checkbox'
              multiple
              value={selectedCountry}
              name='selectedCountry'
              onChange={onCountryChange}
              renderValue={(selected) => {
                let selectedItems = []
                countries.forEach((obj) => {
                  if (selected.includes(obj.value)) {
                    selectedItems.push(obj.label)
                  }
                })
                return selectedItems.join(', ')
              }}
            >
              {countries.map((country) => (
                <MenuItem key={country.value} value={country.value}>
                  <Checkbox
                    checked={selectedCountry.indexOf(country.value) !== -1}
                  />
                  <ListItemText primary={country.label} />
                </MenuItem>
              ))}
            </MultiSelect>
          </FormControl>
        </div> : <div></div>}
      <div className='form-field insurer-filter'>
      <label className='menu-filter-label'>Insurer</label>
        <FormControl variant="outlined" sx={{ m: 1 }}>
          <MultiSelect
            labelId='app-selectedInsurersForDropdown-checkbox-label'
            id='app-selectedInsurersForDropdown-checkbox'
            multiple
            value={selectedInsurerForDropdown}
            name='selectedInsurersForDropdown'
            onChange={onChange}
            renderValue={(selected) => {
              let selectedItems = []
              options.forEach((obj) => {
                if (selected.includes(obj.value)) {
                  selectedItems.push(obj.label)
                }
              })
              return selectedItems.join(', ')
            }}
          >
            {options.map((insurer) => (
              <MenuItem key={insurer.value} value={insurer.value}>
                <Checkbox
                  checked={selectedInsurerForDropdown.indexOf(insurer.value) !== -1}
                />
                <ListItemText primary={insurer.label} />
              </MenuItem>
            ))}
          </MultiSelect>
        </FormControl>
      </div>
    </div>
  );
};

FilterMenu.propTypes = {
  insurer: PropTypes.object.isRequired,
  setPlanSoldDuration: PropTypes.func.isRequired,
  setStartDateRange: PropTypes.func.isRequired,
  getInsurerData: PropTypes.func.isRequired,
  getInsuranceData: PropTypes.func.isRequired,
  setInsurerDropdownData: PropTypes.func.isRequired,
  setCountryData: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  insurer: state.insurer,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  setPlanSoldDuration,
  setStartDateRange,
  getInsurerData, 
  getInsuranceData,
  setInsurerDropdownData,
  setCountryData
})(FilterMenu);
