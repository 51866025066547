import { GET_INSURANCE_IMAGE_SIGNED_URL } from "../constants/apiUrls"

const getInsuranceSignedUrl = async (isoCountry, imageName) => {

  const region = isoCountry?.toUpperCase() === 'IDN' ? 'IDN' : 'IND'

  const response = await fetch(GET_INSURANCE_IMAGE_SIGNED_URL, {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      region,
      imageName
    })
  })
  return (await response.json())?.imageUrl
}

export {
  getInsuranceSignedUrl
}