import React from "react";
import OrdersItem from "./OrdersItem";

const Orders = ({ orders }) => {
  return (
    <div className="cards">
      {orders.map((item, index) => {
        return (
          <div className="cards" key={index}>
            <span className="cards-group text-muted">{item.date}</span>
            <div className="flex display-gap">
            {item.orders.map((order, i) => {
              if (order.id) {
                return <OrdersItem key={"card-" + i} order={order} />;
              }
              return <OrdersItem key={"card-" + i} order={order} />;
            })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Orders;
