import {
  AT_SET_UPDATEMODAL,
  AT_SET_VIEWMODAL,
  AT_SET_CURRENTPROCESS,
  AT_SET_NOTIFICATION,
  AT_SET_HELPMODAL
} from "../actions";

export const defaultUIState = {
  update_modal: false,
  view_modal: false,
  notification: {
    show: false,
    className: "",
    message: "",
  },
  current_process: {
    process: "",
    message: "",
  },
  help_modal: false
};

export default (state = defaultUIState, action) => {
  const { type, payload } = action;

  switch (type) {
    case AT_SET_UPDATEMODAL:
      return {
        ...state,
        update_modal: payload.is_open,
      };
    case AT_SET_VIEWMODAL:
      return {
        ...state,
        view_modal: payload.is_open,
      };
    case AT_SET_NOTIFICATION:
      return {
        ...state,
        notification: {
          show: payload.is_open,
          className: payload.className,
          message: payload.message,
        },
      };
    case AT_SET_CURRENTPROCESS:
      return {
        ...state,
        current_process: {
          process: payload.current_process,
          message: payload.current_message,
        },
      };
    case AT_SET_HELPMODAL:
      return {
        ...state,
        help_modal: payload.is_open,
      };
    default:
      return state;
  }
};
