import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { setViewModal, selectOrder } from '../../actions'
import { connect } from 'react-redux'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { getCurrencySymbol } from '../util/currencySymbols'



const OrdersTableRow = ({ order, setViewModal, selectOrder }) => {
  const renderBadge = (status) => {
    switch (status.toUpperCase()) {
      case 'PENDING':
        return 'badge-alert'
      case 'NOT_DELIVERED':
      case 'REJECTED':
        return 'badge-error'
      case 'ACCEPTED':
        return 'badge-notice'
      case 'DISPATCHED':
        return 'badge-success'
      case 'RECEIVED':
        return 'badge-received'
      case 'PICKED_UP':
        return 'badge-picked'
      case 'DELIVERED':
        return 'badge-delivered'
      case 'RETURNED':
        return 'badge-alert'
      default:
        return 'badge-default'
    }
  }
  var getIsoVal = order.isoCurrency; //to get iso code of selected country e.g 'USD, KHR, PHP, INR'
  const isoCodeLanguage = getCurrencySymbol(getIsoVal); //get the isoCurrencyCode onclick
  let currencyOutput = 'en-US';
  
  let numberFormatOptions = {
    style: 'currency',
    currency: isoCodeLanguage,
    locale: 'en-US'
  };

  switch (isoCodeLanguage) {  
    case 'PHP':
      currencyOutput = 'en-PH' //Philippines
      numberFormatOptions.locale = 'en-PH';
      break;
    case 'IDR':
      currencyOutput = 'id-ID' //Indonesia
      numberFormatOptions.locale = 'id-ID';
      break;
    case 'INR':
      currencyOutput = 'ne-IN'; //India
      numberFormatOptions.locale = 'ne-IN';
      break;
    case 'ZAR':
      currencyOutput = 'en-ZA'; //South Africa
      numberFormatOptions.locale = 'en-ZA';
      break;
    case 'KES':
      currencyOutput = 'sw-KE'; //Kenya
      numberFormatOptions.locale = 'en-ZA';
      break;
    case 'KHR':
      currencyOutput = 'km-KH'; //Cambodia
      numberFormatOptions.locale = 'km-KH';
      break;
    default:
  }
  return (
    <TableRow key={order.patientName}>
      <TableCell className='list-table-data'>
        {order.patientName ? order.patientName : ''}
      </TableCell>
      <TableCell className='list-table-data'>
        {moment(order.currentStatus && order.currentStatus.statusDate).format(
          'MMM DD, YYYY, h:mma'
        )}
      </TableCell>
      <TableCell className='list-table-data'>
        {/* {order.currentStatus.userDisplayName ===
          'undefined undefined undefined' ||
        order.currentStatus.userDisplayName === 'null null'
          ? '--'
          : order.currentStatus.username} */}
        {order.currentStatus.userId ? order.currentStatus.userId : '--'}
      </TableCell>
      <TableCell className='list-table-data'>
        {order.prescriptionNumber ? order.prescriptionNumber : '--'}
      </TableCell>
      <TableCell className='list-table-data'>
        {/* {order.physicianName !== 'null null' ? order.physicianName : '--'} */}
        {new Intl.NumberFormat(currencyOutput, numberFormatOptions).format(order.totalPayable ?
              order.totalPayable.$numberDecimal ? order.totalPayable.$numberDecimal : '--' : '--')}
        {/* {order.totalPayable ? order.totalPayable.$numberDecimal ? order.totalPayable.$numberDecimal : '--' : '--'} */}
      </TableCell>
      <TableCell className='list-table-data'>
        {order.items[0].supplier}
      </TableCell>
      <TableCell className='list-table-data'>
        <div className='flex'>
          <span
            className={
              'badge ' +
              renderBadge(order.currentStatus && order.currentStatus.status)
            }
          />
          <span className='render-badge list-table-data'>
            {order.currentStatus && order.currentStatus.status}
          </span>
        </div>
      </TableCell>
      <TableCell className='list-table-data'>
        <i
          onClick={() => {
            selectOrder(order)
            setViewModal(true)
          }}
          className='end-position fas fa-eye'
        ></i>
      </TableCell>
    </TableRow>
  )
}

OrdersTableRow.propTypes = {
  selectOrder: PropTypes.func,
  setViewModal: PropTypes.func,
  order: PropTypes.object.isRequired,
}

export default connect(null, { selectOrder, setViewModal })(OrdersTableRow)
