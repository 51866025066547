import {
  SET_PLANSOLDDURATION,
  SET_INSURANCEDASHBOARD,
  SET_DATERANGE,
  UPDATE_INSURANCEDASHBOARD,
  SET_SELECTLINEGRAPHOPTION,
  RELOAD_PLAN,
  SET_INSURER_LIST,
  SET_INSURER_DROPDOWN_DATA,
  SET_COUNTRY_DATA
} from './types'
import { setProcess } from './cur_process'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import React from 'react'

import {
  GetInscuranceList,
  // getInscuranceQuestionsById,
  getInscuranceById,
  updateInscuranceStatus,
  getInsurers
} from '../Apis/InscuranceApis/inscranceApi'
import apiNotification from '../lib/api.notification.lib'

const moment = extendMoment(Moment)

export const setSelectedLineGraphOption = (option) => async (dispatch) => {
  dispatch({
    type: SET_SELECTLINEGRAPHOPTION,
    payload: option,
  })
}

export const setPlanSoldDuration =
  (duration, { companies, insured, plans }, { dateStart, dateEnd }) =>
  async (dispatch) => {
    if (duration) {
      dispatch({
        type: SET_PLANSOLDDURATION,
        payload: duration,
      })
      if (dateStart && dateEnd) {
        const range = moment.range(dateStart, dateEnd)
        dispatch({
          type: UPDATE_INSURANCEDASHBOARD,
          payload: {
            companies,
            insured,
            plans: plans.filter((item) =>
              moment(item.duration.dateStart).within(range)
            ),
          },
        })
      }
    } else {
      dispatch({
        type: SET_PLANSOLDDURATION,
        payload: duration,
      })
      dispatch({
        type: UPDATE_INSURANCEDASHBOARD,
        payload: {
          companies,
          insured,
          plans,
        },
      })
    }

  }

// cancel plan
const updateStatus = async (payload, country) => {
  try {
    // res.docHistory.updated.push({
    //   status: "cancelled",
    //   updated: new Date().toISOString(),
    //   userId: user._id,
    //   reason: reason,
    //   reasonCode: reasonCode,
    // });
    // res.orderStatus.status = "cancelled";

    return updateInscuranceStatus(payload, country).then((res) => res)
  } catch (error) {
    console.log(error, 'TO INSERT')
    console.log('Really failed...')
  }
}

const approvePlan = async ({ id, status, user, country }) => {
  const oldInscurance = await getInscuranceById(id, country)
  let oldCurrentStatus = oldInscurance.orderStatus
  let pastStatus = [...oldInscurance.pastStatus]
  try {
    pastStatus.push({
      status: oldCurrentStatus.status,
      statusDate: oldCurrentStatus.acceptedOn,
      userId: user._id,
      username: `${user.first_name} ${user.middle_name} ${user.last_name}`,
      reason: '',
      reasonCode: '',
    })
    // res.updatedBy.display = `${user.first_name} ${user.middle_name} ${user.last_name}`;
    // res.updatedBy.reference = user.username;
    // res.status = status;
    // res.insurer.qualification.isAccepted = true;
    // res.insurer.qualification.reviewedBy = user.username;
    // res.insurer.qualification.reviewed_date = new Date().toISOString();
    // res.lastUpdated = new Date().toISOString();

    let payload = {
      id,
      updateData: {
        orderStatus: {
          status: status,
          reason: '',
          reasonCode: '',
          acceptedOn: new Date(),
          enteredOn: oldCurrentStatus.enteredOn
            ? oldCurrentStatus.enteredOn
            : '',
          processedOn: oldCurrentStatus.processedOn
            ? oldCurrentStatus.processedOn
            : '',
          effectiveDate: oldCurrentStatus.effectiveDate
            ? oldCurrentStatus.effectiveDate
            : '',
          expiryDate: oldCurrentStatus.expiryDate
            ? oldCurrentStatus.expiryDate
            : '',
        },
        pastStatus: pastStatus,
      },
    }
    return updateInscuranceStatus(payload, country).then(() => oldInscurance)
  } catch (error) {
    console.log(error, 'TO INSERT')
    console.log('Really failed...')
  }
}

const issueDatePlan = async ({
  id,
  startDate,
  endDate,
  user,
  certificateNumber,
}) => {
  const res = await this.db.get()
  try {
    res.pastStatus.push({
      status: res.status,
      statusDate: res.lastUpdated,
      userDisplayName: res.updatedBy.display,
      userId: user._id,
      username: user.username,
    })
    res.insurer.certificateNumber = certificateNumber
    // current status
    res.effectiveDate = startDate
    res.expiry = endDate
    res.updatedBy.display = `${user.first_name} ${user.middle_name} ${user.last_name}`
    res.updatedBy.reference = user.username
    res.status = 'active'
    res.lastUpdated = new Date().toISOString()
    return this.db.put(res).then(() => res)
  } catch (error) {
    console.log(error, 'TO INSERT')
    console.log('Really failed...')
  }
}

const beneficiariesMapper = (data) => {
  let beneficiaries = []
  if (data && data.length) {
    data.forEach((element) => {
      beneficiaries.push(
        `${element.givenName && element.givenName} ${
          element.familyName && element.familyName
        } (${element.relationship && element.relationship})`
      )
    })
  }
  return beneficiaries
}

const formatEffectivityDate = (start, end, status) => {
  // if (
  //   status === "pending questionnaire" ||
  //   status === "pending issuance" ||
  //   status === "approved" ||
  //   status === "declined"
  // ) {
  //   return `Add issue date`;
  // } else {
  if (start !== null && end !== null) {
    return `${moment(start).format('ll')} - ${moment(end).format('ll')}`
  } else {
    return '-'
  }
  // }
}

const isEmpty = (obj) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

const compileQuestion = (form) => {
  // let result = getInscuranceQuestionsById('60a51f3322b695298ed20796')
  // result.then(res => {
  //   const data = [];
  //   console.log('res ----------', res)
  //   let mappper = JSON.parse(res[0].quesJson)
  //   let mapData = []
  //   mapData.push(mappper)
  //   console.log('mappper', mappper)
  //   return mapData && mapData.map((item, index) => {
  //     data.push({
  //       question: 'item.questionText',
  //       answer: 'item.answer',
  //     });
  //   });
  //   return data;
  //   console.log('result', res)
  // })
}

export const getInsuranceData = (insurers, dateStart, dateEnd, countries) => async (dispatch) => {
  dispatch(setProcess('Getting list of insurance'))
  try {
    let payload = { insurers, dateStart, dateEnd, countries }
    let res = await GetInscuranceList(payload)
    if (res) {
      const result = {
        plans: res
          .map((policy) => {
            let item = policy
            let attachements = item.additionalImages
            attachements.push(item.insuranceImageUrl)
            return {
              ...item,
              id: item._id,
              isoCountry: item.isoCountry,
              residentId: item.residentId,
              coverage:
                item.beneficiary &&
                item.beneficiary.map((element) => {
                  return (
                    <div>{`${element.givenName && element.givenName} ${
                      element.familyName && element.familyName
                    } (${element.relationship && element.relationship})`}</div>
                  )
                }),
              residentName:
                item.parties &&
                item.parties.length &&
                item.parties.map((name) => {
                  return `${
                    name.givenName !== undefined
                      ? `${name.givenName} ${name.familyName}`
                      : 'No given name'
                  }`
                }),
              dateOfBirth:
                item.parties &&
                item.parties.map((dob) => {
                  return (
                    <div>
                      {dob.dob !== undefined
                        ? moment(dob.dob).format('YYYY-MM-DD')
                        : '0000-00-00T00:00Z'}
                    </div>
                  )
                }),
              age:
                item.parties &&
                item.parties.map((age) => {
                  let dob = moment(age.dob).format('YYYY-MM-DD')
                  return <div>{moment(new Date()).diff(dob, 'years')}</div>
                }),
              _attachments: attachements,
              address: `${item.policyAddress.city} City`,
              gender:
                item.parties &&
                item.parties.map((gender) => {
                  return (
                    <div>
                      {gender.gender !== undefined
                        ? gender.gender
                        : 'No Gender'}
                    </div>
                  )
                }),
              civilStatus: 'single',
              effectiveDate: formatEffectivityDate(
                item.orderStatus.effectiveDate,
                item.orderStatus.expiryDate,
                item.orderStatus.status
              ),
              duration: {
                dateStart:
                  item.orderStatus && item.orderStatus.effectiveDate === null
                    ? ''
                    : moment(
                        item.orderStatus.effectiveDate === null
                          ? '2021-02-02'
                          : item.orderStatus.effectiveDate
                      ).format('YYYY-MM-DD'),
                dateEnd:
                  item.orderStatus && item.orderStatus.expiryDate === null
                    ? ''
                    : moment(item.orderStatus.expiryDate).format('YYYY-MM-DD'),
              },
              insuranceSupplier: item.insuranceSupplier,
              qualification: compileQuestion(item.answers),
              title: item.plan.ENG,
              price:
                item.payment.amount.$numberDecimal &&
                item.payment.amount.$numberDecimal,
              status: item.orderStatus.status,
              createdBy: item.agent.name,
              insuranceImageUrl: item.insuranceImageUrl,
            }
          })
          .sort(
            (a, b) =>
              new Date(b.duration.dateStart) - new Date(a.duration.dateStart)
          ),
      }
      await dispatch({
        type: SET_INSURANCEDASHBOARD,
        payload: result,
      })
      await dispatch(setProcess(''))
    }
  } catch (error) {
    apiNotification(error, dispatch)
    console.error('error', error)
  }
}

// cancel plan
export const updateInsurancePlan =
  (id, insuranceCompany, reason = [], { dateStart, dateEnd }, user, country) =>
  async (dispatch) => {
    try {
      const oldInscurance = await getInscuranceById(id, country)
      let oldCurrentStatus = oldInscurance.orderStatus
      let pastStatus = [...oldInscurance.pastStatus]

      pastStatus.push({
        status: oldCurrentStatus.status,
        statusDate: oldCurrentStatus.acceptedOn,
        userId: user._id,
        username: `${user.first_name} ${user.middle_name} ${user.last_name}`,
        reason: oldCurrentStatus.reason ? oldCurrentStatus.reason : '',
        reasonCode: oldCurrentStatus.reasonCode
          ? oldCurrentStatus.reasonCode
          : '',
      })

      let payload = {
        id,
        updateData: {
          orderStatus: {
            status: reason.status,
            reason: reason.reason,
            reasonCode: reason.reasonCode,
            acceptedOn: oldCurrentStatus.acceptedOn
              ? oldCurrentStatus.acceptedOn
              : '',
            enteredOn: oldCurrentStatus.enteredOn
              ? oldCurrentStatus.enteredOn
              : '',
            processedOn: oldCurrentStatus.processedOn
              ? oldCurrentStatus.processedOn
              : '',
            effectiveDate: oldCurrentStatus.effectiveDate
              ? oldCurrentStatus.effectiveDate
              : '',
            expiryDate: oldCurrentStatus.expiryDate
              ? oldCurrentStatus.expiryDate
              : '',
          },
          pastStatus,
        },
      }
      const res = await updateStatus(payload, country)
      const planIndex = insuranceCompany.plans.findIndex(
        (element) => element.id === id
      )

      // insuranceCompany.plans[planIndex].updatedBy.display = res.updatedBy.display;
      // insuranceCompany.plans[planIndex].updatedBy.reference =
      //   res.updatedBy.reference;
      insuranceCompany.plans[planIndex].status = 'cancelled'
      // insuranceCompany.plans[planIndex].insurer.isAccepted =
      //   res.insurer.isAccepted;
      // insuranceCompany.plans[planIndex].insurer.reviewedBy =
      //   res.insurer.reviewedBy;
      // insuranceCompany.plans[planIndex].insurer.reviewed_date =
      //   res.insurer.reviewed_date;

      if (dateStart !== null && dateEnd !== null) {
        const range = moment.range(dateStart, dateEnd)

        insuranceCompany.plans
          .filter((item) => moment(item.duration.dateStart).within(range))
          .sort((a, b) => a.duration.dateStart - b.duration.dateStart)
      }
      dispatch({
        type: RELOAD_PLAN,
        payload: [],
      })
      dispatch({
        type: SET_INSURANCEDASHBOARD,
        payload: insuranceCompany,
      })
    } catch (error) {
      console.log(error)
    }
  }

export const setStartDateRange =
  (dateStart, dateEnd, { plans, companies, insured }) =>
  async (dispatch) => {
    dispatch({
      type: SET_DATERANGE,
      payload: {
        dateStart: dateStart,
        dateEnd: dateEnd,
      },
    })
    if (dateStart !== null && dateEnd !== null) {
      const range = moment.range(dateStart, dateEnd)
      dispatch({
        type: UPDATE_INSURANCEDASHBOARD,
        payload: {
          companies,
          insured,
          plans: plans
            .filter((item) => moment(item.duration.dateStart).within(range))
            .sort((a, b) => a.duration.dateStart - b.duration.dateStart),
        },
      })
    }
  }

export const approveInsurance =
  (id, insuranceCompany, status, user, country) => async (dispatch) => {
    const planIndex = insuranceCompany.plans.findIndex(
      (element) => element._id === id
    )

    if (id) {
      const body = {
        id,
        status,
        user,
        country
      }

      const res = await approvePlan(body)
      try {
        // insuranceCompany.plans[planIndex].updatedBy.display =
        //   res.updatedBy.display;
        // insuranceCompany.plans[planIndex].updatedBy.reference =
        //   res.updatedBy.reference;
        insuranceCompany.plans[planIndex].status = status

        // insuranceCompany.plans[planIndex].insurer.isAccepted =
        //   res.insurer.isAccepted;
        // insuranceCompany.plans[planIndex].insurer.reviewedBy =
        //   res.insurer.reviewedBy;
        // insuranceCompany.plans[planIndex].insurer.reviewed_date =
        //   res.insurer.reviewed_date;

        dispatch({
          type: RELOAD_PLAN,
          payload: [],
        })

        dispatch({
          type: UPDATE_INSURANCEDASHBOARD,
          payload: insuranceCompany,
        })
      } catch (error) {
        console.error(error)
      }
    }
  }

export const issueInsuranceDate =
  (
    { startDate, endDate, certificateNumber },
    insurance,
    insuranceCompany,
    user,
    country
  ) =>
  async (dispatch) => {
    let id = insurance.id

    let payload = {
      id,
      updateData: {
        orderStatus: {
          acceptedOn: '',
          enteredOn: '',
          processedOn: '',
          effectiveDate: startDate,
          expiryDate: endDate,
          status: 'active',
        },
        certificateNo: certificateNumber,
      },
    }

    const res = await updateInscuranceStatus(payload, country)

    try {
      const planIndex = insuranceCompany.plans.findIndex(
        (element) => element.id === id
      )

      insuranceCompany.plans[planIndex].effectiveDate = formatEffectivityDate(
        startDate,
        endDate
      )
      insuranceCompany.plans[planIndex].certificateNo = certificateNumber
      // insuranceCompany.plans[planIndex].updatedBy.display = res.updatedBy.display;
      // insuranceCompany.plans[planIndex].updatedBy.reference =
      //   res.updatedBy.reference;
      insuranceCompany.plans[planIndex].status = 'active'
      // insuranceCompany.plans[planIndex].insurer.isAccepted =
      //   res.insurer.isAccepted;
      // insuranceCompany.plans[planIndex].insurer.reviewedBy =
      //   res.insurer.reviewedBy;
      // insuranceCompany.plans[planIndex].insurer.reviewed_date =
      //   res.insurer.reviewed_date;

      if (startDate !== null && endDate !== null) {
        const range = moment.range(startDate, endDate)

        insuranceCompany.plans
          .filter((item) => moment(item.duration.dateStart).within(range))
          .sort((a, b) => a.duration.dateStart - b.duration.dateStart)
      }

      dispatch({
        type: RELOAD_PLAN,
        payload: [],
      })

      dispatch({
        type: UPDATE_INSURANCEDASHBOARD,
        payload: insuranceCompany,
      })
    } catch (error) {
      console.error(error)
    }
  }

export const getInsurerData = (countries, isCountryChanged) => async (dispatch, getState) => {
  try {
    const region = countries.includes('idn') ? 'IDN' : 'IND'
    await dispatch(setInsurerDropdownData([]))
    const res = await getInsurers(region, countries)
    if (res && res.length > 0) {
      await dispatch({
        type: SET_INSURER_LIST,
        payload: res,
      })
      if (isCountryChanged) {
        let Insurers = []
        const selectedInsurers = getState().insurer.selectedInsurerForDropdown
        const user = getState().auth.user
        if (user.permissions[0].associate === "All") {
          res.map((obj) => {
            Insurers.push(obj.code)
            return obj
          })
        } else {
          res.map((obj) => {
            if (selectedInsurers.includes(obj.code)) {
              Insurers.push(obj.code)
            }
            return obj
          })
        }
        const dateRange = getState().insurer.dateRange
        await dispatch(getInsuranceData(Insurers, dateRange.dateStart, dateRange.dateEnd, countries))
      }
    } else {
      const result = {plans: []}
      apiNotification({message:'Insurer & Insurance Data not found for selected country'}, dispatch)
      await dispatch({
        type: SET_INSURANCEDASHBOARD,
        payload: result
      })
      await dispatch({
        type: SET_INSURER_LIST,
        payload: []
      })
    }
  } catch (error) {
    apiNotification(error, dispatch)
  }
}

export const setInsurerDropdownData = (insurer) => async (dispatch) => {
  dispatch({
    type: SET_INSURER_DROPDOWN_DATA,
    payload: insurer,
  })
}

export const setCountryData = (countries) => async (dispatch) => {
  dispatch({
    type: SET_COUNTRY_DATA,
    payload: countries,
  })
}
