import jsPDF from "jspdf";
import "jspdf-autotable";
import { format } from "date-fns";

export const generatePDF = (orders,filter_date_start,filter_date_end) => {
  if (orders.length ===0) {
    return
  }
  const doc = new jsPDF('landscape', 'pt');

  const tableColumn = ["Order Id", "Current Status", "Status Updated On", "Delivery Fee", 
  "Sub Total", "Discount", "Discount Code", "Total Payable", "Patient Name", "Sub Orders"];
  
  const startDate = format(new Date(filter_date_start), "MMM dd, yyyy")
  const endDate = format(new Date(filter_date_end), "MMM dd, yyyy")
  doc.setFontSize(12);
  doc.text("Date Range: "+startDate +" to "+ endDate, 572, 30);

  let supplierName = []
  orders.map((item) => {
    item.orders.map((order) => {
      order.items.map((subOrder) =>{
        if (!supplierName.includes(subOrder.supplier)){
          supplierName.push(subOrder.supplier);
        }
      })
    })
  })

  supplierName.map((name,supplierNameIndex)=>{
    const tableRows = [];
    orders.map((item) => {
      item.orders.map((order) => {
        var discountAmount = '';
        var discountCode = ''
        if (order.id) {
          if (!order.discount){
            discountAmount = 'NA';
            discountCode = 'NA';
          }else{
            if(!order.discount.amount){
              discountAmount = 'NA';
            }
            else{
              discountAmount = order.discount.amount.$numberDecimal;
            }
            if (!order.discount.code){
              discountCode = 'NA';
            }else{
              discountCode = order.discount.code;
            }
          }
          let subOrders = [];
          let nameOfSupplier = "";
          const subOrderlength = order.items.length;
          order.items.map((subOrder, index) =>{
            nameOfSupplier = subOrder.supplier;
            if ((index+1) === subOrderlength){
              subOrders.push("Item "+(index+1)+":\nBrand Name: "+subOrder.brandName +
              "\nPrice: "+ subOrder.price.$numberDecimal +
              "\nQuantity: "+ subOrder.qty +
              "\nTax Category: "+ subOrder.tax.category +
              "\nTax Percentage: "+ subOrder.tax.percentage.$numberDecimal +
              "\nTax Type: "+ subOrder.tax.type);
            }else{
              subOrders.push("Item "+(index+1)+":\nBrand Name: "+subOrder.brandName +
              "\nPrice: "+ subOrder.price.$numberDecimal +
              "\nQuantity: "+ subOrder.qty +
              "\nTax Category: "+ subOrder.tax.category +
              "\nTax Percentage: "+ subOrder.tax.percentage.$numberDecimal +
              "\nTax Type: "+ subOrder.tax.type+"\n\n");
            }
          })

          if (nameOfSupplier === name){
            if(!order.totalPayable){
              order = { ...order, totalPayable: {$numberDecimal: ''} }
            }
            if(!order.subTotal){
              order = { ...order, subTotal: {$numberDecimal: ''} }
            }
            const ticketData = [
              order.orderId,
              order.currentStatus.status,
              format(new Date( order.currentStatus.statusDate), "MMM dd, yyyy"),
              order.deliveryFee,
              order.subTotal.$numberDecimal,
              discountAmount,
              discountCode,
              order.totalPayable.$numberDecimal,
              order.patientName,
              subOrders
            ];
            tableRows.push(ticketData);
          }
        }
      })
    })
    doc.text("Supplier Name: "+name,40,45)
    doc.autoTable(tableColumn, tableRows, { startY: 60 });
    if ((supplierNameIndex+1)!==supplierName.length){
      doc.addPage("landscape")
    }
  })
  doc.save(`report.pdf`);
};
