import React, { useState } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import helpMenuData from '../util/helpMenuData'
import '../../styles/Help.styles.scss'


export const HelpMenu = () => {
  const [expanded, setExpanded] = useState(helpMenuData.panelId)

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }
  return (
    <div>
      <h1 className="about-title">{helpMenuData.label}</h1>
      <p className="about-description">{helpMenuData.description}</p>
      {helpMenuData.options.map((app, index) => {
        return (
          <Accordion
            key={'panel' + app.value}
            name='panel'
            expanded={expanded === app.value}
            onChange={handleChange(app.value)}
            className="user-role-panel"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id={'header' + app.value}
            >
              <Typography>{app.label}</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className='header-description' >{app.description}</Typography>
            </AccordionDetails>
          </Accordion>
        )
      })}
    </div>
  )
}
