import { setProcess } from './cur_process'
import { setSelectedOrder } from './order'
import { setCurrentProcess } from './ui'
import {
  GetOrdersList,
  UpdateOrderStatus,
  GetOrderById,
} from '../../src/Apis/MedsOrderApis/ordersApi'
import apiNotification from '../lib/api.notification.lib'
export const AT_SET_ORDERS = 'SET_ORDERS'
export const AT_SET_ORDER = 'AT_SET_ORDER'
const _ = require('lodash')

export const setOrders = (orders = {}) => ({
  type: AT_SET_ORDERS,
  payload: { orders },
})

export const setOrder = (order) => ({
  type: AT_SET_ORDER,
  payload: { order },
})

const reasonString = (code) => {
  switch (code) {
    case 'rc1':
      return 'Unclear Photo'
    case 'rc2':
      return 'Invalid ID/Rx'
    case 'rc3':
      return 'ID/Rx and DisperGo Mismatch'
    case 'rc4':
      return 'Others'
    case 'rc5':
      return 'Stock Out'
    case 'rc6':
      return 'Logistics Issue'
    case 'rc7':
      return 'System Issue'
    case 'rc8':
      return 'Others'
    default:
      return 'No Reason'
  }
}

const concatAddress = (patientAddress) => {
  let address = `
    ${patientAddress.addressLine1 ? patientAddress.addressLine1 : ''}
    ${patientAddress.addressLine2 ? patientAddress.addressLine2 : ''}
    ${patientAddress.direction ? patientAddress.direction : ''}
    ${patientAddress.level4 ? patientAddress.level4 : ''}
    ${patientAddress.level3 ? patientAddress.level3 : ''}
    ${patientAddress.level2 ? patientAddress.level2 : ''},
    ${patientAddress.country ? patientAddress.country : ''} -
    ${patientAddress.postal_code ? patientAddress.postal_code : ''}
    `
  return address
}

export const initOrders = (supplierAssociateCodes, countries) => {
  return (dispatch) => {
    const region = countries.includes('idn') ? 'IDN' : 'IND'
    dispatch(setProcess('Getting list of orders'))
    let paylod = { supplierCode: supplierAssociateCodes, region }
    return GetOrdersList(paylod).then(
      (result) => {
        let subOrdersArr = []
        result &&
          result.map((order) => {
            if (order.subOrders && order.subOrders.length) {
              order.subOrders &&
                order.subOrders.filter((subOrder) => {
                  if (
                    supplierAssociateCodes.includes(subOrder.supplierCode)
                  ) {
                    let supplierData = {
                      currentStatus: subOrder.currentStatus,
                      discount: order.discount ? order.discount : 0,
                      discountIdNumber: order.discountIdNumber,
                      prescriptionImage: order.prescriptionImage,
                      fee: '',
                      id: order._id,
                      subOrderID: subOrder.subOrderID,
                      items: subOrder.items,
                      orderId: '',
                      pastStatuses: subOrder.pastStatuses,
                      patientAddress: concatAddress(order.patientAddress),
                      patientAge: order.patientAge,
                      patientGender: order.patientGender,
                      patientName: order.patientName,
                      physicianLicenseNumber: order.physicianLicenseNumber,
                      physicianName: order.physicianName,
                      prescriptionNumber: order.prescriptionNumber,
                      recipient: order.recipient,
                      totalPayable: subOrder.totalPayable,
                      subTotal: subOrder.subTotal,
                      taxPayable: subOrder.taxPayable,
                      residentId: order.residentId,
                      supplier: order.supplier,
                      deliveryFee: order.deliveryFee,
                      isoCurrency: order.isoCurrency,
                      isoCountry: order.isoCountry,
                      type: order.type
                    }
                    supplierData.currentStatus.status.toUpperCase()
                    if (subOrder.currentStatus && subOrder.currentStatus) {
                      subOrdersArr.push(supplierData)
                    }
                  }
                  if (supplierAssociateCodes.includes('*')) {
                    order.subOrders &&
                      order.subOrders.forEach((subOrder) => {
                        let supplierData = {
                          currentStatus: subOrder.currentStatus,
                          discount: order.discount ? order.discount : '',
                          discountIdNumber: order.discountIdNumber,
                          prescriptionImage: order.prescriptionImage,
                          fee: '',
                          id: order._id,
                          subOrderID: subOrder.subOrderID,
                          items: subOrder.items,
                          orderId: '',
                          pastStatuses: subOrder.pastStatuses,
                          patientAddress: concatAddress(order.patientAddress),
                          patientAge: order.patientAge,
                          patientGender: order.patientGender,
                          patientName: order.patientName,
                          physicianLicenseNumber: order.physicianLicenseNumber,
                          physicianName: order.physicianName,
                          prescriptionNumber: order.prescriptionNumber,
                          recipient: order.recipient,
                          residentId: order.residentId,
                          totalPayable: subOrder.totalPayable,
                          subTotal: subOrder.subTotal,
                          taxPayable: subOrder.taxPayable,
                          supplier: order.supplier,
                          deliveryFee: order.deliveryFee,
                          isoCurrency: order.isoCurrency,
                          isoCountry: order.isoCountry,
                          type: order.type
                        }
                        if (subOrder.currentStatus && subOrder.currentStatus) {
                          subOrdersArr.push(supplierData)
                        }
                      })
                  }
                  return subOrder
                })
            }
            return order
          })
        let subOrdersMapKeys = _.mapKeys(subOrdersArr, 'subOrderID')
        dispatch(setOrders(subOrdersMapKeys))
        dispatch(setProcess(''))
        return subOrdersMapKeys
      },
      (err) => apiNotification(err, dispatch)
    )
  }
}

export const selectOrder = (order) => {
  return (dispatch) => {
    return dispatch(setSelectedOrder(order))
  }
}

/*Function to handle check main order status*/
const handleCheckMainOrderStatus = (order, user) => {
  let orderClosedCountb = 0
  var checkCurrentStatus
  let orderArr = []
  orderArr.push(order)
  let oldCurrentStatus = {}
  orderArr &&
    orderArr.map((record) => {
      oldCurrentStatus = record.currentStatus
      record.subOrders.map((status) => {
        const count =
          status.currentStatus.status === 'REJECTED' ||
          status.currentStatus.status === 'RETURENED' ||
          status.currentStatus.status === 'DELIVERED'
            ? orderClosedCountb++
            : null
        checkCurrentStatus =
          orderClosedCountb === record.subOrders.length
            ? 'CLOSED'
            : 'IN_PROGRESS'
        return status
      })
      return record
    })
  if (checkCurrentStatus) {
    const currentStatus = {
      status: checkCurrentStatus,
      statusDate: new Date().toString(),
      username: oldCurrentStatus.username,
      userId: oldCurrentStatus.userId,
    }
    return currentStatus
  }
}

/*Function to handle order status*/
const updateStatus = async (id, status, order, user, countries) => {
  let new_order = {}
  /*Hnadle order status update*/
  var newCurrentStatus1
  var newPastStatuses
  var pastStatuses = []
  return GetOrderById(id, countries)
    .then((old_order) => {
      /*Handle sub orders status update*/
      if (old_order) {
        old_order.subOrders && old_order.subOrders.filter((item) => {
          if (item.subOrderID === order.subOrderID) {
            let currentStatus = item.currentStatus
            if (item.pastStatuses && item.pastStatuses.length) {
              pastStatuses = [...item.pastStatuses]
            }
            pastStatuses.push({
              reason: currentStatus.reason,
              reasonCode: currentStatus.reasonCode,
              status: currentStatus.status,
              statusDate: new Date().toString(),
              userId: currentStatus.userId,
              username: currentStatus.username,
            })
            item.pastStatuses = pastStatuses
            let newCurrStatus = {
              reason: '',
              reasonCode: '',
              status: status,
              statusDate: new Date().toString(),
              userId: currentStatus.userId,
              username: currentStatus.username,
            }
            /*Set order reason if order status is rejected or on_hold*/
            if (status === 'REJECTED' || status === 'ON_HOLD') {
              newCurrStatus.reason =
                order.reasonCode === 'rc8' || order.reasonCode === 'rc4'
                  ? order.reason
                  : reasonString(order.reasonCode)
              newCurrStatus.reasonCode = order.reasonCode
            }
            item.currentStatus = newCurrStatus
            newCurrentStatus1 = newCurrStatus
            newPastStatuses = pastStatuses

            /*Check the main order status*/
            let mainOrderStatus = handleCheckMainOrderStatus(old_order, user)
            old_order.currentStatus = mainOrderStatus
            return (new_order = old_order)
          }
        })
      }
      
      if (new_order) {
        const updateData = {
          subOrders: new_order.subOrders,
          currentStatus: new_order.currentStatus,
        }
        return UpdateOrderStatus(updateData, id, countries).then(() => ({
          id: order.id,
          subOrderID: order.subOrderID,
          currentStatus: newCurrentStatus1,
          items: order.items,
          pastStatuses: newPastStatuses,
          patientAddress: order.patientAddress,
          patientAge: new_order.patientAge,
          patientGender: new_order.patientGender,
          patientName: new_order.patientName ? new_order.patientName : '',
          physicianName: new_order.physicianName ? new_order.physicianName : '',
          physicianLicenseNumber: new_order.physicianLicenseNumber
            ? new_order.physicianLicenseNumber
            : '',
          residentId: new_order.residentId,
          prescriptionNumber: new_order.prescriptionNumber || 'prescription',
          recipient: new_order.recipient || 'recipient',
          discount: new_order.discount,
          discountIdNumber: new_order.discountIdNumber,
          fee: new_order.delivery,
          supplier: new_order.supplier || 'supplier',
          orderId: 'order id',
          isoCurrency: order.isoCurrency,
          isoCountry: order.isoCountry,
          type: order.type
        })).catch((error) => {
          console.log('Error 292', error)
        })
      }
    })
    .catch((error) => {
      console.log('Error 295', error)
    })
}

export const setOrderStatus = (status, order, user, countries) => {
  return (dispatch, getState) => {
    const order_id = getState().order.id
    dispatch(setCurrentProcess('SET_ORDER_STATUS', 'Updating order status'))
    return updateStatus(order_id, status, order, user, countries)
      .then((updated_order) => {
        if (updated_order && updated_order.id) {
          dispatch(selectOrder(updated_order))
          dispatch(setOrder(updated_order))
          dispatch(setCurrentProcess('', ''))
          return updated_order
        }
      })
      .catch((error) => {
        apiNotification(error, dispatch)
        console.log('ERROR 314', error)
        throw error
      })
  }
}

const updateMedicineCatalogue = (order, user, countries) => {
  return GetOrderById(order.id, countries)
    .then((oldOrder) => {
      let updatedOrder = {}
      /*Handle sub orders Catalogue update*/
      let orderTotalAmount = 0
      let orderPaybleAmount = 0
      oldOrder &&
        oldOrder.map((record) => {
          record &&
            record.subOrders.map((subO) => {
              if (subO.subOrderID !== order.subOrderID) {
                orderTotalAmount += parseFloat(subO.subTotal.$numberDecimal)
                orderPaybleAmount += parseFloat(
                  subO.totalPayable.$numberDecimal
                )
              }
              return subO
            })

          record.subOrders &&
            record.subOrders.filter((item) => {
              if (item.subOrderID === order.subOrderID) {
                item.totalPayable = order.totalPayable
                item.subTotal = parseFloat(order.subTotal).toFixed(2)
                item.items = order.items
                item.currentStatus = order.currentStatus
                item.pastStatuses = order.pastStatuses
                orderTotalAmount += parseFloat(order.subTotal)
                orderPaybleAmount += parseFloat(order.totalPayable)

                let orderSubTotal = parseFloat(orderTotalAmount).toFixed(2)
                let orderTotalPayable = (
                  24 + parseFloat(orderPaybleAmount)
                ).toFixed(2)
                return (updatedOrder = {
                  subOrders: record.subOrders,
                  orderSubTotal: orderSubTotal,
                  orderTotalPayable: orderTotalPayable,
                })
              }
            })
          return record
        })

      return UpdateOrderStatus(updatedOrder, order.id, countries).then(() => ({
        id: order._id,
        subOrderID: order.subOrderID ? order.subOrderID : '',
        currentStatus: order.currentStatus,
        items: order.items,
        pastStatuses: order.pastStatuses,
        patientAddress: order.patientAddress,
        patientAge: order.patientAge,
        physicianName: order.physicianName,
        prescriptionNumber: order.prescriptionNumber,
        patientGender: order.patientGender,
        patientName: order.patientName,
        physicianLicenseNumber: order.physicianLicenseNumber,
        residentId: order.residentId,
        discount: order.discount,
        discountIdNumber: order.discountIdNumber,
        fee: order.delivery,
        supplier: order.supplier,
        orderId: 'order id',
        totalPayable: order.totalPayable,
        subTotal: order.subTotal,
        taxPayable: order.taxPayable,
        isoCurrency: order.isoCurrency,
        isoCountry: order.isoCountry,
        type: order.type
      }))
    })
    .catch((error) => {
      console.log(error, 'TO UPDATE CATALOGUE')
      console.log('Really failed...')
    })
}

export const changeCatalogue = (order, user, countries) => {
  updateMedicineCatalogue(order, user, countries).catch((error) => {
    console.log('ERROR', error)
    throw error
  })
}
