import React from "react";

const Loading = ({ message = "" }) => (
  <div className="loader">
    <div id="wave">
      <span className="dot" />
      <span className="dot" />
      <span className="dot" />
    </div>
    <p className="loader__message">{message}</p>
  </div>
);

export default Loading;
