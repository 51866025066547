import React, { useEffect } from 'react'
import ReactModal from 'react-modal'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { setHelpModal } from '../../actions'
import { HelpMenu } from './HelpMenu'

const HelpModal = ({
  setHelpModal,
  is_helpModal_open
}) => {

  useEffect(() => {
  }, [])

  return (
    <ReactModal
      style={{
        overlay: {
          backgroundColor: 'rgba(0,0,0,0.3)',
        },
      }}
      isOpen={is_helpModal_open}
      onRequestClose={setHelpModal}
      contentLabel='Confirm'
      closeTimeoutMS={200}
      ariaHideApp={false}
    >

      <div>
        <p className='text-muted modal--close' onClick={() => setHelpModal()}>
          close
        </p>
        <HelpMenu/>
      </div>
    </ReactModal>
  )
}

HelpModal.propTypes = {
  is_helpModal_open: PropTypes.bool
}

const mapStateToProps = (state) => ({
  is_helpModal_open: state.ui.help_modal
})

export default connect(mapStateToProps, {
  setHelpModal
})(HelpModal)
