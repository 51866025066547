import api from '../../lib/api.lib'
import {
  GET_ORDER_MEDICINE_URL,
  GET_ORDER_MEDICINE_BY_ID_URL,
  UPDATE_ORDER_MEDICINE_BY_ID_URL,
  GET_SUPPLIERS_URL,
} from '../../constants/apiUrls'

/*API to get orders medicine list*/
export const GetOrdersList = async (payload) => {
  let { supplierCode, region } = payload
  let result = await api.get(
    `${GET_ORDER_MEDICINE_URL}`, {
      params: {supplierCode,  connectionServer: region }
  })
  return result.data.data
}

/*API to update orders medicine status*/
export const UpdateOrderStatus = async (payload, id, countries) => {
  const region = countries.includes('idn') ? 'IDN' : 'IND'
  let result = await api.put(`${UPDATE_ORDER_MEDICINE_BY_ID_URL}/${id}/${region}`, payload)
  return result.data
}

/*API to get order ByID*/
export const GetOrderById = async (payload, countries) => {
  const region = countries.includes('idn') ? 'IDN' : 'IND'
  let result = await api.get(`${GET_ORDER_MEDICINE_BY_ID_URL}/${payload}/${region}`)
  return result.data.data
}
export const getSuppliers = async (region, country) => {
  try {
    const connectionServer = region
    const res = await api.get(`${GET_SUPPLIERS_URL}`, {
      params: { connectionServer, country }
    })
    return res.data.data
  } catch (error) {
    console.log('error in getSuppliers', error)
  }
}
