import { AT_SET_MEDS } from '../actions';

export const defaultMedssstate = {
  '11212': {
    "type": "medication",
    "name": "Diamet",
    "ingredients": [
      "Metformin"
    ],
    "form": "tablet",
    "dosage": "250mg",
    "packageSize": 30,
    "price": 10.00
  },
  '3231':  {
      "type": "medication",
      "name": "Diamet",
      "ingredients": [
        "Metformin"
      ],
      "form": "tablet",
      "dosage": "500mg",
      "packageSize": 30,
      "price": 20.00
  },
  '1314112': {
      "type": "medication",
      "name": "Lamisil",
      "ingredients": [
        "Terbinafine HCl"
      ],
      "form": "tablet",
      "dosage": "250mg",
      "packageSize": 30,
      "price": 30.00
  },
  '45342': {
      "type": "medication",
      "name": "Diovan",
      "ingredients": [
        "Valsartan"
      ],
      "form": "tablet",
      "dosage": "40mg",
      "packageSize": 30,
      "price": 30.00
  },
  'u532904742': {
      "type": "medication",
      "name": "Diovan",
      "ingredients": [
        "Valsartan"
      ],
      "form": "tablet",
      "dosage": "80mg",
      "packageSize": 30,
      "price": 30.00
  }
};

export default (state = defaultMedssstate, action) => {
  switch(action.type) {
    case AT_SET_MEDS:
      return action.payload.meds;
    default:
      return state;
  }
};