import React, { Fragment, useEffect } from 'react'
import { Provider } from 'react-redux'
import configureStore from './store'
import 'normalize.css'
import './styles/styles.scss'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import LoginPage from './containers/LoginPage'
import DashboardPage from './containers/DashboardPage'
import NotFoundPage from './components/NotFoundPage'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import PrivateRoute from './routers/PrivateRoute'
import setAuthToken from './lib/setAuthToken.lib'
import { AT_LOGOUT } from './actions/types'
require('dotenv').config()

if (process.env.REACT_APP_AWHDISPERGO_ENV === 'LIVE') {
  console.log(
    'ENV:AWHDISPERGO_AUTHAPIURL',
    'https://authapi.reach52.com/users/token'
  )
  console.log(
    'ENV:AWHDISPERGO_CBSYNCURL',
    'https://sgw-live.reach52.com/awhdispergodb/'
  )
} else if (process.env.REACT_APP_AWHDISPERGO_ENV === 'UAT') {
  console.log(
    'ENV:AWHDISPERGO_AUTHAPIURL',
    'https://test.curis.online/auth/users/token'
  )
  console.log(
    'ENV:AWHDISPERGO_CBSYNCURL',
    'https://sg-uat.reach52.com/awhdispergodb/'
  )
} else if (process.env.REACT_APP_AWHDISPERGO_ENV === 'india') {
  console.log(
    'ENV:AWHDISPERGO_AUTHAPIURL',
    'https://auth-uat.reach52.com/users/token'
  )
  console.log(
    'ENV:AWHDISPERGO_CBSYNCURL',
    'https://in-sgw.reach52.com/awhdispergodb/'
  )
} else if (process.env.REACT_APP_AWHDISPERGO_ENV === 'test') {
  console.log(
    'ENV:AWHDISPERGO_AUTHAPIURL',
    'https://test.curis.online/auth/users/token'
  )
  console.log(
    'ENV:AWHDISPERGO_CBSYNCURL',
    'https://in-sgw-test.reach52.com/awhdispergodb/'
  )
} else {
  console.log(
    'ENV:AWHDISPERGO_AUTHAPIURL',
    'https://demo-auth.reach52.com/users/token'
  )
  console.log(
    'ENV:AWHDISPERGO_CBSYNCURL',
    'https://demo-sync.reach52.com/awhdispergodb/'
  )
}

const store = configureStore()
// useEffect(() => {
//   store.dispatch(loadUser());
// }, []);

const App = () => {
  useEffect(() => {
    if (localStorage.token && localStorage.user) {
      setAuthToken(localStorage.token, JSON.parse(localStorage.user))
    }

    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token || !localStorage.user) {
        store.dispatch({
          type: AT_LOGOUT,
          uid: '',
          isAuthenticated: false,
          error: 'Invalid session, please relogin',
          user: [],
        })
      }
    })
  }, [])
  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <Switch>
            <Route exact path='/' component={LoginPage} />
            <PrivateRoute path='/dashboard' exact component={DashboardPage} />
            <Route component={NotFoundPage} />
          </Switch>
        </Fragment>
      </Router>
    </Provider>
  )
}
export default App
