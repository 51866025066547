import React, { Fragment, useState, useEffect } from 'react'
import { getImageSignedURL } from '../actions'
import ReactModal from 'react-modal'
import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { setViewModal, setNotification, setOrderStatus } from '../actions'
import OrderHistory from './Orders/OrderHistory'
import { toggleCatalogueModal } from '../actions/util'
import { getCurrencySymbol } from './util/currencySymbols'
import StorefrontIcon from '@mui/icons-material/Storefront';
require('dotenv').config()

const ViewModal = ({
  setViewModal,
  setNotification,
  selected_order,
  setOrderStatus,
  is_viewmodal_open,
  toggleCatalogueModal,
  util: { isCatalogueModalOpen },
  auth: { user },
  selectedCountriesForDropdown
}) => {
  let total = 0
  let subTotalAmount = 0
  let vatValue = 0.12
  let [reason, setReason] = useState('rc1')
  let [onHoldReason, setOnHoldReason] = useState('rc5')
  let [otherReason, setOtherReason] = useState('')
  let [otherOnHoldReason, setOtherOnHoldReason] = useState('')
  let [items, setItems] = useState([])
  let [isPrescreption, setIsPrescription] = useState(false)
  let subOrderDiscount = 0
  var PRESRIPTION_IMAGE
  var getIsoVal = selected_order.isoCurrency; //to get iso code of selected country e.g 'USD, KHR, PHP, INR'

  if (process.env.REACT_APP_AWHDISPERGO_ENV === 'UAT') {
    if (
      selected_order.isoCountry &&
      selected_order.isoCountry.toUpperCase() === 'IDN'
    ) {
      PRESRIPTION_IMAGE =
        'https://storage.googleapis.com/r52-indonesia-test-bucket/'
    } else {
      PRESRIPTION_IMAGE = 'https://r52-testbucket.ap-south-1.linodeobjects.com/'
    }
  }

  if (process.env.REACT_APP_AWHDISPERGO_ENV === 'DEMO') {
    // PRESRIPTION_IMAGE = 'https://r52-demo-bucket.ap-south-1.linodeobjects.com/'
    PRESRIPTION_IMAGE = 'https://r52-testbucket.ap-south-1.linodeobjects.com/'
  }

  if (process.env.REACT_APP_AWHDISPERGO_ENV === 'LIVE') {
    if (
      selected_order.isoCountry &&
      selected_order.isoCountry.toUpperCase() === 'IDN'
    ) {
      PRESRIPTION_IMAGE =
        'https://storage.googleapis.com/r52-indonesia-prod-bucket/'
    } else {
      PRESRIPTION_IMAGE =
        'https://r52-masterapp-live.s3.ap-southeast-1.amazonaws.com/'
    }
  }

  const handleUpdateOrder = (getStatus, order) => {
    if (selected_order) {
      setOrderStatus(getStatus, order, user, selectedCountriesForDropdown)
      setViewModal(false)
      setNotification(
        true,
        getStatus,
        `${selected_order.patientName} has successfully ${getStatus}`
      )

      setTimeout(() => {
        setNotification(false)
      }, 5000)
    }
  }

  useEffect(() => {
    setItems(selected_order.items)
    setIsPrescription(false)
  }, [selected_order.items])

  const roundNumbers = (num) => {
    return num.toFixed(2)
  }

  const getDiscontedPrice = (subTotal, discount) => {
    let dcnt = discount && discount.$numberDecimal ? discount.$numberDecimal : 0
    let discountPrice = parseFloat((subTotal * dcnt) / 100)
    subOrderDiscount = parseFloat(discountPrice)
    return parseFloat(discountPrice)
  }

  const calculateDiscount = (num, discount, qty = 1) => {
    // let discountItem = num * (discount / 100) * qty;
    // totalDiscount += discountItem;
    // return discountItem;
  }

  const getsubOrderPaybleAmount = (totalAmount, deliveryFee = 0) => {
    selected_order.totalPayable = (
      (parseFloat(totalAmount) + parseFloat(deliveryFee)) - parseFloat(subOrderDiscount)
    )
    selected_order.subTotal = (parseFloat(totalAmount) + parseFloat(deliveryFee))
    return ((parseFloat(totalAmount) + parseFloat(deliveryFee)) - parseFloat(subOrderDiscount))
  }

  const getVatExempt = (price, vatValue) => roundNumbers(price / (1 + vatValue))

  const isoCodeLanguage = getCurrencySymbol(getIsoVal); //get the isoCurrencyCode onclick
  let currencyOutput = 'en-US';
  
  let numberFormatOptions = {
    style: 'currency',
    currency: isoCodeLanguage,
    locale: 'en-US'
  };

  switch (isoCodeLanguage) {  
    case 'PHP':
      currencyOutput = 'en-PH' //Philippines
      numberFormatOptions.locale = 'en-PH';
      break;
    case 'IDR':
      currencyOutput = 'id-ID' //Indonesia
      numberFormatOptions.locale = 'id-ID';
      break;
    case 'INR':
      currencyOutput = 'ne-IN'; //India
      numberFormatOptions.locale = 'ne-IN';
      break;
    case 'ZAR':
      currencyOutput = 'en-ZA'; //South Africa
      numberFormatOptions.locale = 'en-ZA';
      break;
    case 'KES':
      currencyOutput = 'sw-KE'; //Kenya
      numberFormatOptions.locale = 'en-ZA';
      break;
    case 'KHR':
      currencyOutput = 'km-KH'; //Cambodia
      numberFormatOptions.locale = 'km-KH';
      break;
    default:
  }

  const region = selected_order.isoCountry === 'IDN' ? 'IDN' : 'IND';

  const [prescriptionImage, setPrescriptionImage] = useState('')
  const [orderID, setOrderID] = useState('')

  useEffect(() => {
    if (selected_order.id && orderID !== selected_order.id) {
      setOrderID(selected_order.id)
      if (selected_order.prescriptionImage.includes('.png')) {
        getImageSignedURL({ imageName: selected_order.prescriptionImage, region })
          .then(({ imageUrl }) => setPrescriptionImage(imageUrl))
      } else {
        const publicImageURL = selected_order.prescriptionImage.startsWith('https://')
          ? `${selected_order.prescriptionImage}`
          : `${PRESRIPTION_IMAGE}${selected_order.prescriptionImage}`
        setPrescriptionImage(publicImageURL)
      }
    }
  })

  return (
    <ReactModal
      style={{
        overlay: {
          backgroundColor: 'rgba(0,0,0,0.3)',
        },
      }}
      isOpen={is_viewmodal_open}
      onRequestClose={setViewModal}
      contentLabel='Confirm'
      closeTimeoutMS={200}
      ariaHideApp={false}
      className='modal'
    >
      <div className='modal__image'>
        {items &&
          items.filter((obj) => {
            if (isPrescreption === false) {
              if (obj.prescriptionRequired === true) {
                setIsPrescription(true)
              }
            }
          })}
        {selected_order.prescriptionImage ? (
          <img
            className='modal__image'
            src={prescriptionImage}
            alt='Order validation'
          />
        ) : null}
      </div>
      <div className='modal__content'>
        <p className='text-muted modal--close' onClick={() => setViewModal()}>
          close
        </p>
        <h5 className='modal__title'>
          {selected_order.type ==='order'
          ? <i className='fas fa-user-alt' />
          : <StorefrontIcon sx={{ color: '#ab218f' }} />}
          <span>
            <span className='text-reach'>
              {selected_order.type ==='order'
              ? selected_order.id && selected_order.patientName
              : 'Merchant'}
            </span>{' '}
            <br />
            {selected_order.type ==='order'
              ? selected_order.patientGender + ', ' + selected_order.patientAge + 'yrs. old '
              : selected_order.id && selected_order.patientName}
            <br />
          </span>
        </h5>
        {(selected_order.currentStatus &&
          selected_order.currentStatus.status === 'REJECTED') ||
        (selected_order.currentStatus &&
          selected_order.currentStatus.status === 'ON_HOLD') ? (
          <div>
            {selected_order.currentStatus !== undefined &&
              'reasonCode' in selected_order.currentStatus && (
                <div className='modal__subtitle'>
                  <i className='fas fa-hand-paper' />
                  <span>
                    {selected_order.currentStatus.status === 'REJECTED' && (
                      <span className='text-reach'>Rejected Reason</span>
                    )}{' '}
                    {selected_order.currentStatus.status === 'ON_HOLD' && (
                      <span className='text-reach'>On Hold Reason</span>
                    )}{' '}
                    <br />
                    {selected_order.currentStatus.reason}
                  </span>
                </div>
              )}
          </div>
        ) : null}
        {selected_order.prescriptionNumber && (
          <div className='modal__subtitle'>
            <i className='fas fa-prescription' />
            <span>
              <span className='text-muted'>Prescription number</span>
              {selected_order.prescriptionNumber}
            </span>
          </div>
        )}
        <div className='modal__subtitle'>
          <i className='fas fa-hashtag' />
          <span>
            <span className='text-muted'>Order Id</span>
            {selected_order.orderId}
          </span>
        </div>
        <div className='modal__subtitle'>
          <i className='fas fa-hashtag' />
          <span>
            <span className='text-muted'>Sub Order Id</span>
            {selected_order.subOrderID ? selected_order.subOrderID : '--'}
          </span>
        </div>
        <div className='modal__subtitle'>
          <i className='fas fa-map-marked-alt' />
          <span>
            <span className='text-muted'>Address</span>
            {selected_order.patientAddress
              ? selected_order.patientAddress
              : '--'}
          </span>
        </div>
        <div className='modal__subtitle'>
          <i className='far fa-calendar-alt' />
          <span>
            <span className='text-muted'>Date</span>
            {selected_order.currentStatus !== undefined &&
              moment(selected_order.currentStatus.statusDate).format(
                'MMMM Do YYYY, h:mm:ss a'
              )}
          </span>
        </div>
        <div className='modal__subtitle'>
          <i className='fas fa-user-md' />
          <span>
            <span className='text-muted'>Physician</span>
            {selected_order.physicianName === null || selected_order.physicianName === "" || selected_order.physicianName === "null null"
              ? ''
              : selected_order.physicianName}{' '}
            -{' '}
            {selected_order.physicianLicenseNumber === null
              ? ''
              : selected_order.physicianLicenseNumber}
          </span>
        </div>
        {selected_order.recipient && (
          <div className='modal__subtitle'>
            <i className='fas fa-user-friends' />
            <span>
              <span className='text-muted'>Recipient</span>
              {selected_order.recipient}
            </span>
          </div>
        )}
        <div className='modal__subtitle'>
          <i className='fas fa-shopping-cart' />
          <div className='modal__orders'>
            <span className='text-muted'>Orders</span>
            {items && items ? (
              <ul className='list-inorder orders'>
                {items &&
                  items.map((medicine, index) => {
                    subTotalAmount +=
                      medicine.price &&
                      medicine.price.$numberDecimal * medicine.qty
                    if (
                      !medicine.statusReason &&
                      medicine.statusReason !== 'OOS'
                    ) {
                      if (
                        medicine.tax &&
                        medicine.tax.isIncluded !== undefined &&
                        medicine.tax.isIncluded !== null &&
                        medicine.tax.isIncluded
                      ) {
                        // if item have isVatExclusive and not discounted
                        total +=
                          medicine.price &&
                          medicine.price.$numberDecimal * medicine.qty
                      } else if (
                        medicine.tax.included !== undefined &&
                        !medicine.tax.included &&
                        !medicine.tax.included &&
                        Object.entries(
                          selected_order.discount ? selected_order.discount : ''
                        ).length === 0
                      ) {
                        // if item isVatExclusive exempt and discounted
                        total +=
                          medicine.price &&
                          medicine.price.$numberDecimal * medicine.qty
                      } else {
                        // if item isVatExclusive exempt and not discounted
                        total +=
                          getVatExempt(
                            medicine.price && medicine.price.$numberDecimal,
                            vatValue
                          ) * medicine.qty
                      }
                      return (
                        <li key={index}>
                          <span className='table-order-medicine'>
                            <span>
                              {medicine.brandName ? medicine.brandName : ''} (
                              {medicine.genericName ? medicine.genericName : ''}
                              )
                            </span>
                          </span>
                          <Fragment>
                            <span className='table-order-qty'>
                              {medicine.qty} x{' '}
                              {new Intl.NumberFormat(currencyOutput, numberFormatOptions).format
                              (medicine.price && medicine.price.$numberDecimal)}{' '}
                              <br />
                            </span>
                            <span className='table-order-total'>
                              {new Intl.NumberFormat(currencyOutput, numberFormatOptions).format
                                (medicine.price &&
                                medicine.price.$numberDecimal * medicine.qty)}
                            </span>
                          </Fragment>
                          {medicine.tax &&
                            medicine.tax.included !== undefined &&
                            !medicine.tax.included !== null &&
                            Object.entries(selected_order.discount).length !==
                              0 && (
                              <Fragment>
                                <span className='table-order-qty'>
                                  Price <br />
                                  VAT Exempt <br />
                                  {medicine.qty} x{' '}
                                  {getVatExempt(
                                    medicine.price &&
                                      medicine.price.$numberDecimal,
                                    vatValue
                                  )}{' '}
                                  <br />
                                  Discount
                                </span>
                                <span className='table-order-total'>
                                  <span>
                                    &#8369;
                                    {medicine.price &&
                                      medicine.price.$numberDecimal}
                                  </span>
                                  <br />
                                  <span>
                                    &#8369;
                                    {getVatExempt(
                                      medicine.price &&
                                        medicine.price.$numberDecimal,
                                      vatValue
                                    )}
                                  </span>
                                  <br />
                                  <span>
                                    &#8369;
                                    {(
                                      getVatExempt(
                                        medicine.price &&
                                          medicine.price.$numberDecimal,
                                        vatValue
                                      ) * medicine.qty
                                    )}
                                  </span>
                                  <br />
                                  <span>
                                    &#8369;
                                    {calculateDiscount(
                                      getVatExempt(
                                        medicine.price &&
                                          medicine.price.$numberDecimal,
                                        vatValue
                                      ),
                                      selected_order.discount.amount,
                                      medicine.qty
                                    )}
                                  </span>
                                </span>
                              </Fragment>
                            )}
                        </li>
                      )
                    }
                    return (
                      <p key={`${index}-outofstock`}>
                        {medicine.medicationName} out of stock
                      </p>
                    )
                  })}
              </ul>
            ) : null}

            <ul className='list-inorder orders no-style'>
              {selected_order.discountIdNumber !== undefined && (
                <li>
                  <span className='table-order-qty'>Discount ID Number</span>
                  <span className='table-order-total'>
                    {selected_order.discountIdNumber}
                  </span>
                </li>
              )}
              {selected_order.discount !== undefined && (
                <li>
                  <span className='table-order-qty'>Sub Total</span>
                  <span className='table-order-total'>
                    {new Intl.NumberFormat(currencyOutput, numberFormatOptions).format(subTotalAmount)}
                  </span>
                </li>
              )}
              {selected_order.deliveryFee !== undefined && (
                <li>
                  <span className='table-order-qty'>Delivery Fee</span>
                  <span className='table-order-total'>
                    {new Intl.NumberFormat(currencyOutput, numberFormatOptions).format(selected_order.deliveryFee)}
                  </span>
                </li>
              )}
              {selected_order.discount !== undefined && (
                <li>
                  <span className='table-order-qty'>Discount</span>
                  <span className='table-order-total'>
                    {new Intl.NumberFormat(currencyOutput, numberFormatOptions).format
                      (getDiscontedPrice(subTotalAmount && subTotalAmount,
                        selected_order.discount && selected_order.discount.amount))}
                  </span>
                </li>
              )}
              {selected_order.deliveryFee !== undefined && (
                <li>
                  <span className='table-order-qty'>Sub Order Total</span>
                  <span className='table-order-total'>
                    {new Intl.NumberFormat(currencyOutput, numberFormatOptions).format
                    (getsubOrderPaybleAmount(subTotalAmount && subTotalAmount, selected_order.deliveryFee))}
                  </span>
                </li>
              )}
            </ul>
            <p>
              status :
              <span>
                {selected_order.id && selected_order.currentStatus.status}
              </span>
            </p>
            {selected_order.id &&
              (selected_order.currentStatus.status === 'PENDING' ||
                selected_order.currentStatus.status === 'ACCEPTED') && (
                <div className='modal__subtitle modal-label'>
                  <label
                    className='modal__subtitle text-muted'
                    onClick={(e) => {
                      toggleCatalogueModal(
                        !isCatalogueModalOpen.bool,
                        selected_order
                      )
                    }}
                  >
                    Update Catalogue
                  </label>
                </div>
              )}
            <div className='modal__subtitle orderHistory modal-label'>
              <input
                name='toggleHistory'
                id='toggleHistory'
                type='checkbox'
                className='history-toggle'
              />
              <label
                htmlFor='toggleHistory'
                className='modal__subtitle text-muted'
              >
                View Order History
              </label>
              <OrderHistory selected_order={selected_order} />
            </div>
          </div>
          <div />
        </div>

        <div className='text-right'>
          {selected_order.id &&
            selected_order.currentStatus.status === 'PENDING' && (
              <div>
                <input type='checkbox' id='rejectBtn' />
                <button className='button--danger button--default'>
                  <label htmlFor='rejectBtn'>Reject</label>
                </button>
                <div className='modal-prompt'>
                  <div className='modal' tabIndex='-1' role='dialog'>
                    <div className='modal-dialog' role='document'>
                      <div className='modal-content'>
                        <div className='modal-header'>
                          <h5 className='modal-title'>Confirmation</h5>
                          <button
                            type='button'
                            className='close'
                            data-dismiss='modal'
                            aria-label='Close'
                          >
                            <span aria-hidden='true'>&times;</span>
                          </button>
                        </div>
                        <div className='modal-body'>
                          <p>
                            Change order status to{' '}
                            <b className='text-danger'>Reject</b>
                          </p>
                          <div className='form-group'>
                            <label htmlFor='sel1'>Select reason:</label>
                            <select
                              onChange={(e) => setReason(e.target.value)}
                              className='browser-default custom-select'
                            >
                              <option value='rc1'>Unclear Photo</option>
                              <option value='rc2'>Invalid ID/Rx</option>
                              <option value='rc3'>
                                ID/Rx and DisperGo Mismatch
                              </option>
                              <option value='rc4'>Others</option>
                            </select>
                            {reason === 'rc4' && (
                              <textarea
                                className='form-control'
                                id='exampleFormControlTextarea1'
                                rows='3'
                                placeholder='Write reject reason here...'
                                onChange={(e) => setOtherReason(e.target.value)}
                              ></textarea>
                            )}
                          </div>
                        </div>
                        <div className='modal-footer'>
                          <button
                            type='button'
                            className='btn btn-default'
                            data-dismiss='modal'
                          >
                            <label htmlFor='rejectBtn'>Close</label>
                          </button>
                          <button
                            onClick={() => {
                              selected_order.reasonCode = reason
                              selected_order.reason =
                                reason === 'rc4' ? otherReason : reason
                              handleUpdateOrder('REJECTED', selected_order)

                              setReason('rc1')
                              setOtherReason('')
                            }}
                            type='button'
                            className='btn button--primary button--default'
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                &nbsp;
                <button
                  className='button--processing button--default'
                  onClick={() => handleUpdateOrder('ACCEPTED', selected_order)}
                >
                  Approve
                </button>
              </div>
            )}
          {selected_order.id &&
            (selected_order.currentStatus.status === 'ACCEPTED' ||
            selected_order.currentStatus.status === 'NOT_DELIVERED') && (
              <div>
                <input type='checkbox' id='onHoldBtn' />
                <button className='button--danger button--default'>
                  <label htmlFor='onHoldBtn'>On Hold</label>
                </button>
                <div className='modal-prompt'>
                  <div className='modal' tabIndex='-1' role='dialog'>
                    <div className='modal-dialog' role='document'>
                      <div className='modal-content'>
                        <div className='modal-header'>
                          <h5 className='modal-title'>Confirmation</h5>
                          <button
                            type='button'
                            className='close'
                            data-dismiss='modal'
                            aria-label='Close'
                          >
                            <span aria-hidden='true'>&times;</span>
                          </button>
                        </div>
                        <div className='modal-body'>
                          <p>
                            Change order status to{' '}
                            <b className='text-danger'>On Hold</b>
                          </p>
                          <div className='form-group'>
                            <label htmlFor='sel1'>Select reason:</label>
                            <select
                              onChange={(e) => setOnHoldReason(e.target.value)}
                              className='browser-default custom-select'
                            >
                              <option value='rc5'>Stock Out</option>
                              <option value='rc6'>Logistics Issue</option>
                              <option value='rc7'>System Issue</option>
                              <option value='rc8'>Others</option>
                            </select>
                            {onHoldReason === 'rc8' && (
                              <textarea
                                className='form-control'
                                id='exampleFormControlTextarea1'
                                rows='3'
                                placeholder='Write on hold reason here...'
                                onChange={(e) =>
                                  setOtherOnHoldReason(e.target.value)
                                }
                              ></textarea>
                            )}
                          </div>
                        </div>
                        <div className='modal-footer'>
                          <button
                            type='button'
                            className='btn btn-default'
                            data-dismiss='modal'
                          >
                            <label htmlFor='onHoldBtn'>Close</label>
                          </button>

                          <button
                            onClick={(e) => {
                              selected_order.reasonCode = onHoldReason
                              selected_order.reason =
                                onHoldReason === 'rc8'
                                  ? otherOnHoldReason
                                  : onHoldReason
                              handleUpdateOrder('ON_HOLD', selected_order)

                              setOnHoldReason('rc5')
                              setOtherOnHoldReason('')
                            }}
                            type='button'
                            className='btn button--primary button--default'
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                &nbsp;
                <button
                  className='button--success button--default'
                  onClick={() =>
                    handleUpdateOrder('DISPATCHED', selected_order)
                  }
                >
                  Dispatch
                </button>
              </div>
            )}
          {selected_order.id &&
            selected_order.currentStatus.status === 'ON_HOLD' && (
              <div>
                <button
                  className='button--primary button--default'
                  onClick={() =>
                    handleUpdateOrder(
                      selected_order.pastStatuses[
                        selected_order.pastStatuses.length - 1
                      ].status,
                      selected_order
                    )
                  }
                >
                  Resume
                </button>
              </div>
            )}

          {selected_order.id &&
            selected_order.currentStatus.status === 'DISPATCHED' && (
              <div>
                <button
                  className='button--not-delivered button--default'
                  onClick={() =>
                    handleUpdateOrder('NOT_DELIVERED', selected_order)
                  }
                >
                  Not Delivered 
                </button>
                &nbsp;
                <button
                  className='button--delivered button--default'
                  onClick={() =>
                    handleUpdateOrder('DELIVERED', selected_order)
                  }
                >
                  Delivered
                </button>
              </div>
            )}
        </div>
      </div>
    </ReactModal>
  )
}

ViewModal.propTypes = {
  selected_order: PropTypes.object,
  is_viewmodal_open: PropTypes.bool,
  toggleCatalogueModal: PropTypes.func,
  util: PropTypes.object,
}

const mapStateToProps = (state) => ({
  selected_order: state.order,
  is_viewmodal_open: state.ui.view_modal,
  util: state.util,
  auth: state.auth,
  selectedCountriesForDropdown: state.suppliers.selectedCountriesForDropdown
})

export default connect(mapStateToProps, {
  setViewModal,
  setNotification,
  setOrderStatus,
  toggleCatalogueModal,
})(ViewModal)
