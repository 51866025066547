import medsDB from "../store/db/medsDB";

import { setProcess } from "./cur_process";

export const AT_SET_MEDS = "SET_MEDS";
const _ = require("lodash");

export const setMeds = (meds = {}) => ({
  type: AT_SET_MEDS,
  payload: { meds },
});

export const initMeds = (curuserid) => {
  return (dispatch) => {
    dispatch(setProcess("Getting list of meds"));
    return medsDB
      .init(curuserid, [], (result) => {
        console.log("MEDSDB", result);
      })
      .then(() => medsDB.getAllDocs(undefined))
      .then((result) => {
        const meds = _.mapKeys(result, "id");
        dispatch(setMeds(meds));
        dispatch(setProcess(""));
        return meds;
      });
  };
};
