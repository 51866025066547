import { AT_SET_TEXT_FILTER, AT_SET_STATUS_FILTER, AT_SET_ORDER_TYPE_FILTER, AT_SET_DATERANGE } from "../actions";

const filtersReducerDefaultState = {
  text: "",
  status: { value: 'ALL', label: 'ALL' },
  dateStart: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
  dateEnd:new Date(Date.now()),
  orderType: { value: 'all', label: 'ALL'}
};
const filtersReducer = (state = filtersReducerDefaultState, action) => {
  switch (action.type) {
    case AT_SET_TEXT_FILTER:
      return { ...state, text: action.payload.text };
    case AT_SET_STATUS_FILTER:
      return { ...state, status: action.payload.status };
    case AT_SET_ORDER_TYPE_FILTER:
      return { ...state, orderType: action.payload.orderType};
    case AT_SET_DATERANGE:
      return { 
        ...state, 
        dateStart: new Date(action.payload.dateStart),
        dateEnd: new Date(action.payload.dateEnd)
      };
    default:
      return state;
  }
};

export default filtersReducer;
