import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { ResponsiveLine } from "@nivo/line";
import { connect } from "react-redux";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { setSelectedLineGraphOption } from "../../actions/insurer";

const moment = extendMoment(Moment);

const generateCount = (plans, title) => {
  // count sold plan per date start
  const data = plans
    .filter((plan) => plan.title === title)
    .map((plan) => {
      let planCount = {
        x: plan.duration.dateStart,
        y: 1,
      };
      return planCount;
    })
    .reduce((acc, obj) => {
      let key = obj["x"];
      acc[key] = !acc[key] ? [] : acc[key];

      acc[key].push({
        x: key,
        y: 1,
      });

      return acc;
    }, {});

  // format object to add in line graph
  let mapKeys = Object.keys(data).sort();
  const graphData = mapKeys.map((item) => {
    let res = {
      x: item,
      y: data[item].length,
    };
    return res;
  });
  return graphData;
};

const generateOverall = (plans, title) => {
  // count sold plan per date start
  const data = plans
    .map((plan) => {
      let planCount = {
        x: plan.duration.dateStart,
        y: 1,
      };
      return planCount;
    })
    .reduce((acc, obj) => {
      let key = obj["x"];
      acc[key] = !acc[key] ? [] : acc[key];

      acc[key].push({
        x: key,
        y: 1,
      });

      return acc;
    }, {});

  // format object to add in line graph
  let mapKeys = Object.keys(data).sort();
  const graphData = mapKeys.map((item) => {
    let res = {
      x: item,
      y: data[item].length,
    };
    return res;
  });
  return graphData;
};

const generateSale = (plans) => {
  let sales = plans
    .map((plan) => {
      let planCount = {
        x: plan.duration.dateStart,
        y: plan.price,
      };
      return planCount;
    })
    .reduce((acc, curr) => {
      let key = curr["x"];
      acc[key] = !acc[key] ? 0 : acc[key];
      acc[key] += parseInt(curr.y);
      return acc;
    }, {});

  // format object to add in line graph
  let mapKeys = Object.keys(sales).sort();
  const graphData = mapKeys.map((item) => {
    let res = {
      x: item,
      y: sales[item],
    };
    return res;
  });

  return graphData;
};

const graphObject = (data, option) => {
  return [
    {
      id: `${option}`,
      color: "hsl(217, 70%, 50%)",
      data: data,
    },
  ];
};
const setLineGraphData = (plans, dateStart, dateEnd, option) => {
  let dateS = moment(dateStart).format('YYYY-MM-DD')
  let dateE = moment(dateEnd).format('YYYY-MM-DD')
  const range = moment.range(dateS, dateE);

  let activePlans = plans.filter((item) => item.status === "approved");

  if (option === "sales")
    return graphObject(
      generateSale(activePlans).filter((item) => moment(item.x).within(range)),
      option
    );

  if (option === "overall")
    return graphObject(
      generateOverall(activePlans).filter((item) =>
        moment(item.x).within(range)
      ),
      option
    );

  if (option)
    return graphObject(
      generateCount(activePlans, `${option} plan`).filter((item) =>
        moment(item.x).within(range)
      ),
      option
    );
};

const InsuredGraphLine = ({
  setSelectedLineGraphOption,
  insurer: {
    selectedSoldDuration,
    selectedLineGraphOption,
    insuranceCompany: { plans },
    soldPlansCount: {
      unit,
      grossSales,
      soldPlans,
      goldPlans,
      silverPlans,
      bronzePlans,
      platinumPlans,
      basicPlans,
    },
    dateRange: { dateStart, dateEnd },
  },
}) => {

  const plansCatalogue = [
    {
      title: "overall",
      count: soldPlans,
    },
  ];
  return (
    <Fragment>
      <ul className="graphLineHeader">
        <li>
          <div
            className={`card + ${selectedLineGraphOption === "sales" ? "is-active" : ""
              }`}
            onClick={(e) => setSelectedLineGraphOption("sales")}
          >
            <div className="card-body">
              <h5 className="card-title">Gross Sales</h5>
              <p className="card-text">{grossSales}</p>
            </div>
          </div>
        </li>
        {plansCatalogue.map((plan, i) => (
          <li key={i}>
            <div
              className={`card + ${selectedLineGraphOption === plan.title ? "is-active" : ""
                }`}
              onClick={(e) => setSelectedLineGraphOption(plan.title)}
            >
              <div className="card-body">
                <h5 className="card-title">{plan.title} Sold Plans</h5>
                <p className="card-text">{plan.count}</p>
              </div>
            </div>
          </li>
        ))}
      </ul>
      {selectedSoldDuration && dateStart && dateEnd && (
        <div className="graphLineChart">
          {dateStart && dateEnd && (
            <ResponsiveLine
              data={setLineGraphData(
                plans,
                dateStart,
                dateEnd,
                selectedLineGraphOption
              )}
              margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
              xScale={{ type: "point" }}
              yScale={{ type: "linear", min: "auto", max: "auto" }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: "bottom",
                tickSize: 1,
                tickPadding: 5,
                tickRotation: 0,
                legend: `${moment(dateStart).format("ll")} to ${moment(
                  dateEnd
                ).format("ll")}`,
                legendOffset: 36,
                legendPosition: "middle",
              }}
              axisLeft={{
                orient: "left",
                tickSize: 1,
                tickPadding: 5,
                tickRotation: 0,
                legend: `${selectedLineGraphOption} sold plan`,
                legendOffset: -50,
                legendPosition: "middle",
              }}
              colors={{ scheme: "nivo" }}
              pointColor={{ from: "color", modifiers: [] }}
              pointBorderWidth={1}
              pointBorderColor={{ from: "color", modifiers: [] }}
              pointLabel="y"
              pointLabelYOffset={-12}
              useMesh={true}
              legends={[
                {
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: "left-to-right",
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          )}
        </div>
      )}
    </Fragment>
  );
};

InsuredGraphLine.propTypes = {
  insurer: PropTypes.object.isRequired,
  setSelectedLineGraphOption: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  insurer: state.insurer,
});

export default connect(mapStateToProps, { setSelectedLineGraphOption })(
  InsuredGraphLine
);
