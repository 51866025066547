import api from '../../lib/api.lib'
import {
  GET_INSCURANCE_URL,
  GET_INSCURE_URL,
  UPDATE_INSCURANCE_STATUS_URL,
  GET_INSCURANCE_BY_ID_URL,
  GET_INSCURANCE_QUESTIONS_BY_ID_URL,
  GET_INSURER_URL
} from '../../constants/apiUrls'

/*API to get inscurance list*/
export const GetInscuranceList = async (paylod) => {
  let { dateStart, dateEnd, insurers, countries  } = paylod
  dateStart = dateStart === undefined || dateStart === null ? '' : dateStart
  dateEnd = dateEnd === undefined || dateEnd === null ? '' : dateEnd
  const region = countries.includes('idn') ? 'IDN' : 'IND'
  let result = await api.get(`${GET_INSCURANCE_URL}`, {
    params: { supplierCode: insurers, startDate: dateStart, endDate: dateEnd, connectionServer: region }
  })
  let getFinalData = async () => {
    const finalData = await result.data.data.map((dataItem) => {
      result.data.inscuranceProduct.map((item) => {
        if (dataItem.catalogItem.catId === item._id) {
          let plan = item.name
          dataItem.plan = plan
        } else {
          dataItem.plan = {ENG: '', KAN: '', KHM: ''}
        }
      })
      return dataItem
    })
    return finalData
  }

  let sendData = await getFinalData()
  console.log('sendData', sendData)
  return result.data.data
}

/*API to get inscurance list*/
export const GetInscure = async (paylod) => {
  let result = await api.post(GET_INSCURE_URL, paylod)
  return result.data.data
}

/*API to get inscurance list*/
export const updateInscuranceStatus = async (paylod, country) => {
  const region = country.includes('idn') ? 'IDN' : 'IND'
  let result = await api.put(`${UPDATE_INSCURANCE_STATUS_URL}/${region}`, paylod)
  return result.data.data
}

/*API to get inscurance ById*/
export const getInscuranceById = async (paylod, country) => {
  const region = country.includes('idn') ? 'IDN' : 'IND'
  let result = await api.get(`${GET_INSCURANCE_BY_ID_URL}/${paylod}/${region}`, paylod)
  return result.data.data
}

/*API to get inscurance ById*/
export const getInscuranceQuestionsById = async (paylod, country) => {
  const region = country.includes('idn') ? 'IDN' : 'IND'
  let result = await api.get(
    `${GET_INSCURANCE_QUESTIONS_BY_ID_URL}/${paylod}/${region}`,
    paylod
  )
  if (result) return result.data.data
}

export const getInsurers = async (connectionServer, country) => {
  try {
    const res = await api.get(`${GET_INSURER_URL}`, {
      params: { connectionServer, country }
    })
    return res.data.data
  } catch (error) {
    console.log('error in getInsurers', error)
  }
}
