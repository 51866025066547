import { combineReducers } from "redux";

import auth from "./auth";
import meds from "./meds";
import orders from "./orders";
import order from "./order";
import filters from "./filters";
import cur_process from "./cur_process";
import ui from "./ui";
import insurer from "./insurer";
import util from './util'
import suppliers from "./suppliers";
export default combineReducers({
  auth,
  meds,
  orders,
  order,
  cur_process,
  ui,
  filters,
  insurer,
  util,
  suppliers
});
