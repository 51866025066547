import { 
  AT_SET_SELECTED_ORDER,
  AT_SET_SELECTED_ATTCHMNT
} from '../actions';

export const defaultOrderState = {
  'attachment': {
    photo: null
  }
};

export default (state = defaultOrderState, action) => {
  switch(action.type) {
    case AT_SET_SELECTED_ORDER:
      return {
        ...state,
        ...action.payload.order
      };
    case AT_SET_SELECTED_ATTCHMNT:
      return {
        ...state,
        'attachment': action.payload.attachment
      };
    default:
      return state;
  }
};