export const AT_SET_UPDATEMODAL = "SET_UPDATEMODAL";
export const AT_SET_VIEWMODAL = "SET_VIEWMODAL";
export const AT_SET_CURRENTPROCESS = "SET_CURRENTPROCESS";
export const AT_SET_NOTIFICATION = "SET_NOTIFICATION";
export const AT_SET_HELPMODAL = 'SET_HELPMODAL';

export const setUpdateModal = (is_open) => ({
  type: AT_SET_UPDATEMODAL,
  payload: { is_open },
});

export const setViewModal = (is_open) => ({
  type: AT_SET_VIEWMODAL,
  payload: { is_open },
});

export const setNotification = (is_open, className, message) => ({
  type: AT_SET_NOTIFICATION,
  payload: { is_open, className, message },
});

export const setCurrentProcess = (current_process, current_message) => ({
  type: AT_SET_CURRENTPROCESS,
  payload: { current_process, current_message },
});

export const setHelpModal = (is_open) => ({
  type: AT_SET_HELPMODAL,
  payload: { is_open },
});
