
import { SET_SUPPLIER_DROPDOWN_DATA, SET_SUPPLIER_LIST, SET_COUNTRY_DROPDOWN_DATA } from "../actions/types";

const initialState = {
  suppliers:[],
  selectedSuppliersForDropdown:[],
  selectedCountriesForDropdown: []
};
    
export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SUPPLIER_LIST:
      return {
        ...state,
        suppliers: payload,
      };
    case SET_SUPPLIER_DROPDOWN_DATA:
      return {
        ...state,
        selectedSuppliersForDropdown: payload
      }; 
    case SET_COUNTRY_DROPDOWN_DATA:
      return {
        ...state,
        selectedCountriesForDropdown: payload
      }
    default:
      return state;
  }
};
