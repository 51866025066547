import React, { useEffect, useState } from 'react'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import defaultImage from '../../images/image-not-available.jpg'
import { getInsuranceSignedUrl } from '../../lib/signedurls'

const MyImageGallery = ({ data }) => {
  
  const [imagesList, setImagesList] = useState([])

  useEffect(() => {
    (async () => {
      const imagesUrls = []
      for await (let image of data._attachments || []) {
        try {
          const imageUrl = await getInsuranceSignedUrl(data.isoCountry, image)
          imagesUrls.push({ original: imageUrl })
        } catch (error) {
          
        }
      }
      setImagesList(imagesUrls)
    })()
  }, [data.isoCountry, data._attachments])

  const properties = {
    showBullets: true,
    useBrowserFullscreen: false,
    showPlayButton: false,
    showFullscreenButton: false,
    disableThumbnailSwipe: false,
    disableThumbnailScroll: false,
    disableKeyDown: false,
    onErrorImageURL: defaultImage,
    items: imagesList,
  }

  return imagesList ? <ImageGallery {...properties} /> : <div>No images found</div>
}

export default MyImageGallery
