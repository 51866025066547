module.exports.getCurrencySymbol = (isoCode) => {
    let currencySymbol = isoCode?.toUpperCase()
    return {
        PHP: "PHP",   //Philippines ₱
        KHR: "KHR",   //Cambodia ៛
        INR: "INR",   //India ₹
        RP:  "IDR",   //Indonesia Rp
        IDR: "IDR",  //Indonesia Rp
        KES: "KES",  //Kenya Ksh
        ZAR: "ZAR", //South Africa R
        USD: 'USD',   //Other $
    }[currencySymbol]
}