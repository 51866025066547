import api from '../lib/api.lib'
import {
  CENTRAL_AUTH_IMAGES,
} from '../constants/apiUrls'

export const GetImageSignedURL = async ({ imageName, region='IND' }) => {
  const url = `${CENTRAL_AUTH_IMAGES}/resident/medical-prescription`

  const { data } = await api.post(url, { imageName, region })
  return data
}