import axios from 'axios'

const api = axios.create({
  baseURL: '/',
  headers: {
    'Content-Type': 'application/json',
    Authorization:
      localStorage.token && localStorage.user
        ? `Bearer ${localStorage.token}`
        : '',
  },
})

// /*
//   NOTE: intercept any request from the api
//   and add authorization headers.
//   ie. Altered token send with authorization headers.
// */
// api.interceptors.request.use((req) => {
//   // `req` is the Axios request config, so you can modify the `headers`.
//   req.headers.Authorization =
//     localStorage.token && localStorage.user
//       ? `Bearer ${localStorage.token}`
//       : ''
//   return req
// })

/*
  NOTE: intercept any error responses from the api
 and check if the token is no longer valid.
 ie. Token has expired or user is no longer
 authenticated.
 logout the user if the token has expired
*/
api.interceptors.response.use(
  (res) => res,

  (err) => Promise.reject(err)
)

export default api
