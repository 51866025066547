import {
  INSURANCEMODAL,
  SET_CONFIRMATION,
  SET_CATALOGUE_MODAL,
} from "../actions/types";

const initialState = {
  isInsuranceModalOpen: {
    data: [],
    bool: false,
  },
  isCatalogueModalOpen: {
    data: [],
    bool: false,
  },
  isConfirmation: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case INSURANCEMODAL:
      return {
        ...state,
        isInsuranceModalOpen: payload,
      };
    case SET_CATALOGUE_MODAL:
      return {
        ...state,
        isCatalogueModalOpen: payload,
      };
    case SET_CONFIRMATION:
      return {
        ...state,
        isConfirmation: payload.bool,
      };
    default:
      return state;
  }
};
