import axios from 'axios'
import { AT_LOGIN, AT_LOGOUT } from './types'
import {
  AUTH_SESSION_API_URL,
} from '../constants/centralizedAuthHostUrls'

let API_LOGIN_URL
if (process.env.REACT_APP_AWHDISPERGO_ENV === 'LIVE') {
  API_LOGIN_URL = 'https://test.curis.online/auth/users/token'
} else if (process.env.REACT_APP_AWHDISPERGO_ENV === 'UAT') {
  API_LOGIN_URL = 'https://auth-uat.reach52.com/users/token'
} else if (process.env.REACT_APP_AWHDISPERGO_ENV === 'india') {
  API_LOGIN_URL = 'https://auth-uat.reach52.com/users/token'
} else if (process.env.REACT_APP_AWHDISPERGO_ENV === 'test') {
  API_LOGIN_URL = 'https://auth-uat.reach52.com/users/token'
} else if (process.env.REACT_APP_AWHDISPERGO_ENV === 'DEMO') {
  API_LOGIN_URL = 'https://demo-auth.reach52.com/users/token'
}

const login = (uid, isAuthenticated, error = '', user) => ({
  type: AT_LOGIN,
  uid,
  isAuthenticated,
  error,
  user,
})

export const startLogin = (username, password) => async (dispatch) => {
  const res = await axios
    .post(API_LOGIN_URL, {
      username,
      password,
      appId: 'dispergo-web',
    })
    .catch((error) => {
      dispatch(login('', false, 'Incorrect credentials'))
    })
  try {
    if (res.status === 200) {
      //const { _id } = res.data.user
      localStorage.setItem('token', res.data.token)
      localStorage.setItem('user', JSON.stringify(res.data.user))
      await dispatch(login(username, true, '', res.data.user))
    } else {
      login('', false, 'Error encountered. Please try again.')
    }
  } catch (error) {
    dispatch(login('', false, 'Incorrect credentials'))
  }
}

export const logout = (uid) => ({
  type: AT_LOGOUT,
})

export const startLogout = () => async (dispatch) => {

  axios.delete(AUTH_SESSION_API_URL, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(res => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    dispatch(logout())
  }).catch(err => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    dispatch(logout())
  })
  
  // insurerDB.close()
  // location.reload();
  // window.location.reload()
}
