import PouchDB from "pouchdb";

let MEDSDB_URL;
let DATABASE_ENV;
if (process.env.REACT_APP_AWHDISPERGO_ENV === "LIVE") {
  MEDSDB_URL = "https://sgw-live.reach52.com/awhdispergodb/";
  DATABASE_ENV = "live";
} else if (process.env.REACT_APP_AWHDISPERGO_ENV === "UAT") {
  MEDSDB_URL = "https://sg-uat.reach52.com/awhdispergodb/";
  DATABASE_ENV = "uat";
} else if (process.env.REACT_APP_AWHDISPERGO_ENV === "india") {
  MEDSDB_URL = "https://in-sgw.reach52.com/awhdispergodb/";
  DATABASE_ENV = "india";
} else if (process.env.REACT_APP_AWHDISPERGO_ENV === "test") {
  MEDSDB_URL = "https://in-sgw-test.reach52.com/awhdispergodb/";
  DATABASE_ENV = "india";
} else if (process.env.REACT_APP_AWHDISPERGO_ENV === "DEMO") {
  MEDSDB_URL =
    "https://demo-sync.reach52.com/awhdispergodb/";
  DATABASE_ENV = "demo";
}

const MEDSDB_DOCTYPE = "medication";

class medsPouchDB {
  constructor() {
    this.db = "";
    this.syncHandler = undefined;
  }

  getOneShotOptions = (docids) => {
    return {
      filter: (doc) => doc.type && doc.type === MEDSDB_DOCTYPE,
      docids: [...docids],
    };
  };

  getLiveOptions = (docids) => {
    return {
      live: true,
      retry: true,
      ...this.getOneShotOptions(docids),
    };
  };

  init = (curuserid, docids, onReceiveMessages) => {
    this.db = new PouchDB(`AWHDISPERGOmedicineDBv1${DATABASE_ENV}`, {
      auth: {
        username: "health",
        password: "P@ssword1!-H",
      },
      skip_setup: true,
    });
    return this.db.replicate
      .from(MEDSDB_URL, this.getOneShotOptions(docids))
      .then((result) => {
        this.syncHandler = this.db
          .sync(MEDSDB_URL, this.getLiveOptions(docids))
          .on("change", (info) => {
            if (info.direction === "pull") {
              onReceiveMessages(info);
            } else {
              console.log("ewan");
            }
          })
          .on("paused", () => {
            // console.log("pause");
          })
          .on("error", (error) => {
            console.log("error");
          });
        return result;
      })
      .catch((error) => {
        console.log("error main", error);
        throw error;
      });
  };

  getAllDocs = (curuserid) => {
    return this.db
      .allDocs({
        include_docs: true,
      })
      .then((docs) => {
        return docs.rows.map((row) => ({
          id: row.doc._id,
          dosage: row.doc.dosage,
          form: row.doc.form,
          ingredients: row.doc.ingredients,
          name: row.doc.name,
          packageSize: row.doc.packageSize,
          price: row.doc.price,
        }));
      });
  };

  close = () => {
    this.syncHandler.cancel();
    return this.db.destroy();
  };
}

const medsDB = new medsPouchDB();

export {
  DATABASE_ENV,
  MEDSDB_URL,
  MEDSDB_DOCTYPE,
  medsPouchDB,
  medsDB as default,
};
