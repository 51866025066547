import * as XLSX from "xlsx";
import { format } from "date-fns";

export const generateExcel = (orders) => {
  if (orders.length ===0) {
    return
  }

  const wb = XLSX.utils.book_new();

  const tableColumn = ["Order Id", "Current Status", "Status Updated On", "Item", "Brand Name",
  "price", "Quantity", "Tax Category", "Tax Percentage", "Tax Type", "Delivery Fee",
  "Discount", "Discount Code", "Payable", "Currency", "Patient Name", "Agent Name", "Type"];

  orders.map((item) => {
    let data = []
    item.orders.map((order) => {
      let discountAmount
      let discountCode
      if (!order.discount){
        discountAmount = '0';
        discountCode = 'NA';
      }else{
        if(!order.discount.amount){
          discountAmount = '0';
        }
        else{
          discountAmount = order.discount.amount.$numberDecimal;
        }
        if (!order.discount.code){
          discountCode = 'NA';
        }else{
          discountCode = order.discount.code;
        }
      }
      if(!order.totalPayable){
        order = { ...order, totalPayable: {$numberDecimal: ''} }
      }
      order.items.map((subOrder) => {
        const payableAmount = parseFloat(subOrder.price.$numberDecimal) * subOrder.qty *
          ((100+parseFloat(subOrder.tax.percentage.$numberDecimal))/100) + order.deliveryFee - parseFloat(discountAmount)

        const ticketData = [
          order.orderId,
          order.currentStatus.status,
          format(new Date( order.currentStatus.statusDate), "MMM dd, yyyy"),
          subOrder.brandName,
          subOrder.supplier,
          subOrder.price.$numberDecimal,
          subOrder.qty,
          subOrder.tax.category,
          subOrder.tax.percentage.$numberDecimal,
          subOrder.tax.type,
          order.deliveryFee,
          discountAmount,
          discountCode,
          payableAmount,
          order.isoCurrency.toUpperCase(),
          order.patientName,
          order.currentStatus.userId,
          order.type === 'order' ? 'agent_order' : 'merchant_order'
        ];
        data.push(ticketData);
        return subOrder
      })
      return order
    })
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, item.date);
    XLSX.utils.sheet_add_aoa(ws, [tableColumn], { origin: "A1" });
    return item
  })

XLSX.writeFile(wb, 'report.xlsx');
};
