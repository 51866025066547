import { INSURANCEMODAL, SET_CONFIRMATION, SET_CATALOGUE_MODAL } from "./types";

export const toggleInsuranceModal = (bool, data = []) => async (dispatch) => {
  dispatch({
    type: INSURANCEMODAL,
    payload: {
      data,
      bool,
    },
  });
};

export const toggleCatalogueModal = (bool, data = []) => async (dispatch) => {
  dispatch({
    type: SET_CATALOGUE_MODAL,
    payload: {
      data,
      bool: bool,
    },
  });
};

export const toggleConfirmation = (bool) => async (dispatch) => {
  dispatch({
    type: SET_CONFIRMATION,
    payload: { bool },
  });
};
