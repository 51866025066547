import { AT_LOGIN, AT_LOGOUT, AT_SET_PROCESS } from '../actions/types'

const initialState = {
  uid: localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user')).username
    : '',
  isAuthenticated:
    localStorage.getItem('token') && localStorage.getItem('user')
      ? true
      : false,
  error: '',
  processing: '',
  user: localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case AT_LOGIN:
      return {
        uid: action.uid,
        isAuthenticated: action.isAuthenticated,
        error: action.error,
        processing: '',
        user: action.user,
      }
    case AT_LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: [],
      }
    case AT_SET_PROCESS:
      return {
        ...state,
        processing: action.process,
      }
    default:
      return state
  }
}
