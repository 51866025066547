import moment from "moment";
import { convertUuidToUuid58 } from "uuid58";

export const selectOrders = (orders, { text = "", status, dateStart, dateEnd, orderType }) => {
  orders.sort((a, b) => {
    return (
      new Date(b.currentStatus.statusDate) -
      new Date(a.currentStatus.statusDate)
    );
  });

  let filteredOrder = orders.filter((order) => {
    if (order.id) {
      order.orderId = convertUuidToUuid58(order.id);
    }

    if (order.currentStatus && order.currentStatus.userDisplayName === undefined && order.currentStatus.userDisplayName === null) {
      order.currentStatus.userDisplayName = "No agent";
    }

    if (order.patientAddress === undefined && order.patientAddress === null) {
      order.patientAddress = "No address";
    }

    const textMatch =
      text === "" ||
      order.patientName && order.patientName.toLowerCase().includes(text.toLowerCase()) ||
      // order.patientAddress && order.patientAddress.toLowerCase().includes(text) ||
      // order.physicianLicenseNumber && order.physicianLicenseNumber.toLowerCase().includes(text.toLowerCase()) ||
      order.currentStatus && order.currentStatus.userId &&
      order.currentStatus.userId.toLowerCase().includes(text.toLowerCase());

    const statusMatch = status.value.toUpperCase() === "ALL" || status.value.toUpperCase() === order.currentStatus.status;
    const orderTypeMatch = orderType.value.toUpperCase() === "ALL" || orderType.value === order.type;
    const startDate = new Date(dateStart).toDateString();
    const endDate = new Date(dateEnd).toDateString();
    const statusDate = new Date(order.currentStatus.statusDate).toDateString();
    const startDateMatch =
      ((new Date(startDate)) <= (new Date(statusDate))) && 
      ((new Date(endDate)) >= (new Date(statusDate)))
    
    return textMatch && statusMatch && startDateMatch && orderTypeMatch;
  });

  const groupDate = filteredOrder.reduce((date, item) => {
    const orderDate = moment(item.currentStatus && item.currentStatus.statusDate !== undefined && item.currentStatus.statusDate !== null && item.currentStatus.statusDate).format(
      "dddd, MMMM Do YYYY"
    );
    if (!date[orderDate]) date[orderDate] = [];

    date[orderDate].push(item);
    return date;
  }, {});

  const groupArray = Object.keys(groupDate).map((date) => {
    return {
      date,
      orders: groupDate[date],
    };
  });

  return groupArray;
};
